import { isMobile } from '../scrollLock'

const collapseAfterSecondPageViewOnMobilePartnerIds: string[] = [
  'AgAAAmwAZFTIZXgTTuO8kvscTOFn7A' // Dev Publisher
]

const collapseAfterSecondPageViewOnAllDevicesPartnerIds: string[] = [
  'AgAAAdQA_ezrEu-uOvOyVmmyaxyYYQ' // Callaway
]

const collapseAfterIdleTimeOnMobilePartnerIds: string[] = []

const collapseAfterIdleTimeOnAllDevicesPartnerIds: string[] = []

export function getFloatingCtaMinimizationStrategyForPartner(id: string) {
  if (collapseAfterSecondPageViewOnMobilePartnerIds.includes(id)) {
    // If the user is on mobile, auto-minimize the floating CTA after the second page view.
    if (isMobile()) {
      return FloatingCTAMinimizationStrategy.AFTER_SECOND_PAGEVIEW
    }

    // If not on mobile, never minimize the floating CTA.
    return FloatingCTAMinimizationStrategy.NEVER
  }

  if (collapseAfterSecondPageViewOnAllDevicesPartnerIds.includes(id)) {
    return FloatingCTAMinimizationStrategy.AFTER_SECOND_PAGEVIEW
  }

  if (collapseAfterIdleTimeOnMobilePartnerIds.includes(id)) {
    // If the user is on mobile, auto-minimize the floating CTA after the idle time.
    if (isMobile()) {
      return FloatingCTAMinimizationStrategy.AFTER_IDLE_TIME
    }

    // If not on mobile, never minimize the floating CTA.
    return FloatingCTAMinimizationStrategy.NEVER
  }

  if (collapseAfterIdleTimeOnAllDevicesPartnerIds.includes(id)) {
    return FloatingCTAMinimizationStrategy.AFTER_IDLE_TIME
  }

  // Default to never minimizing the floating CTA.
  return FloatingCTAMinimizationStrategy.NEVER
}

export const FloatingCTAMinimizationStrategy = {
  /** Never minimize automatically (can still be minimized manually by user) */
  NEVER: 'NEVER',
  AFTER_IDLE_TIME: 'AFTER_IDLE_TIME',
  AFTER_SECOND_PAGEVIEW: 'AFTER_SECOND_PAGEVIEW'
} as const
export type FloatingCTAMinimizationStrategy = keyof typeof FloatingCTAMinimizationStrategy

export const MINIMIZATION_IDLE_TIME_IN_SECONDS = 6
