export const TenantId = {
  BLUXOME_GOODS: 'AgAAAdQADZzMXCB9Trim0bWXv4whMQ',
  CALLAWAY: 'AgAAAdQA_ezrEu-uOvOyVmmyaxyYYQ',
  CANGSHAN: 'AgAAAdQAV5glDypuPGyTQk8wUHUreQ',
  CURATED: 'AgAAAdQAAAAAAAAAAAAAAAAAAAAAAA',
  NIDECKER: 'AgAAAdQAWCM_3nOQOkKCGIZopAZXHA'
} as const

export type TenantId = (typeof TenantId)[keyof typeof TenantId]

export const PublisherId = {
  GOLF_LINK: 'AgAAAmwAk9ygvwQ9Q1-EHwZ9eECndw',
  GOLFER_GEEKS: 'AgAAAmwA4m9dU7hPSqi1h0NoSqqxeA',
  SNOWBOARDING_PROFILES: 'AgAAAmwAf5joC7ZNTiWq5j2lxYPt5Q',
  SNOWBRAINS: 'AgAAAmwA4QjByz-HSICp-l2x_JQ6HQ',
  THE_GOOD_RIDE: 'AgAAAmwAdrADZNg0SpSvMF1BI1Jg8w'
} as const

export type PublisherId = (typeof PublisherId)[keyof typeof PublisherId]

export const PartnerId = { ...TenantId, ...PublisherId } as const

export type PartnerId = TenantId | PublisherId
