import type { InjectedComponent } from '../../types'
import homepageBannerHtml from './homepage-banner.html?raw'
import driverSrpBannerHtml from './driver-srp-banner.html?raw'

const callawayBackgroundImages: Required<Pick<InjectedComponent, 'bgSm' | 'bgMd' | 'bgLg'>> = {
  bgSm: 'https://www.callawaygolf.com/dw/image/v2/AADH_PRD/on/demandware.static/-/Sites-CG4-Library/en_US/v1694724719317/fitting/selector-tools/02-Selector-Tool-Drivers-2023_2000x2000.jpg?sw=375&q=70',
  bgMd: 'https://www.callawaygolf.com/dw/image/v2/AADH_PRD/on/demandware.static/-/Sites-CG4-Library/en_US/v1694724719317/fitting/selector-tools/02-Selector-Tool-Drivers-2023_2000x2000.jpg?sw=750&q=70',
  bgLg: 'https://www.callawaygolf.com/dw/image/v2/AADH_PRD/on/demandware.static/-/Sites-CG4-Library/en_US/v1694724719317/fitting/selector-tools/02-Selector-Tool-Drivers-2023_2000x2000.jpg?sw=1800&q=70'
}

export const callawayHomepageBannerComponent: InjectedComponent = {
  id: 'caas-callaway-homepage-banner',
  selector: '#home-hero',
  html: homepageBannerHtml,
  ...callawayBackgroundImages
}

export const callawayDriverSrpBannerComponent: InjectedComponent = {
  id: 'caas-callaway-driver-srp-banner',
  selector: '#main',
  html: driverSrpBannerHtml,
  allowedPathnames: [
    '/golf-clubs/drivers/',
    '/womens-golf/womens-drivers/',
    '/clubs/womens/drivers/',
    '/custom-fitting/online-driver-selector/'
  ],
  ...callawayBackgroundImages
}
