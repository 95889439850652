export async function getIsAdBlockerDetected() {
  let isDetected = false

  await fetch('/adblockdetection.js', {
    mode: 'no-cors'
  }).catch(() => {
    isDetected = true
  })

  return isDetected
}
