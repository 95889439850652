import { readPageHistoryFromStorage, writePageHistoryToStorage, StorageType } from './localStorage'

export interface PageHistoryHandlerInterface {
  sessionLength?: number
  push(newHistoryItem: string): void
}

class SessionHistoryIdleTimeHandler implements PageHistoryHandlerInterface {
  private storageType: StorageType = 'sessionStorage'
  private idleTime: number = 0
  private interval: ReturnType<typeof setInterval> | undefined
  private pageHistory: Array<{ url: string; occurredAt: number }>
  private afterSecondPageVisitAndIdleCallback: () => void

  constructor(afterSecondPageVisitAndIdleCallback: () => void | Promise<void>) {
    this.push = this.push.bind(this)
    this.monitor = this.monitor.bind(this)
    this.reset = this.reset.bind(this)
    this.destroy = this.destroy.bind(this)
    this.pageHistory = readPageHistoryFromStorage(this.storageType)
    this.afterSecondPageVisitAndIdleCallback = () => {
      Promise.resolve(afterSecondPageVisitAndIdleCallback()).then(this.destroy)
    }
    window.addEventListener('unload', this.destroy)
  }

  public push(newHistoryItem: string) {
    // Add this item to the list of history items, then save to sessionStorage.
    if (this.pageHistory[this.pageHistory.length - 1]?.url !== newHistoryItem) {
      this.pageHistory.push({ url: newHistoryItem, occurredAt: Date.now() })
    }
    // If the `pageHistory` now includes at least 2 items, then start the `monitor` process.
    if (this.pageHistory.length > 1) {
      this.monitor()
    }
    writePageHistoryToStorage(this.storageType, this.pageHistory)
  }

  public get sessionLength() {
    return this.pageHistory.length
  }

  private monitor() {
    // Destroy any previously-begun monitoring process before starting another.
    this.destroy()
    this.interval = setInterval(() => {
      // The interval increase the `idleTime` by one every 1000ms (= 1s).
      this.idleTime += 1
      if (this.idleTime > 3) {
        // After idling for more than 3s, invoke the provided `callback` (which clears the interval after executing).
        this.afterSecondPageVisitAndIdleCallback()
      }
    }, 1000)
    // These event listeners reset the `idleTime` to 0 any time the user interacts with the page, but don't stop the interval.
    document.addEventListener('mousemove', this.reset)
    document.addEventListener('keypress', this.reset)
  }

  private reset() {
    this.idleTime = 0
  }

  private destroy() {
    document.removeEventListener('mousemove', this.reset)
    document.removeEventListener('keypress', this.reset)
    clearInterval(this.interval)
    this.interval = undefined
    this.idleTime = 0
  }
}

class LocalHistoryChangeHandler implements PageHistoryHandlerInterface {
  private storageType: StorageType = 'localStorage'
  private pageHistory: Array<{ url: string; occurredAt: number }>
  private _callback: (pageHistory: Array<{ url: string; occurredAt: number }>) => void

  constructor(callback: (pageHistory: Array<{ url: string; occurredAt: number }>) => void) {
    this.push = this.push.bind(this)
    this.pageHistory = readPageHistoryFromStorage(this.storageType)
    this._callback = callback
  }

  public push(newHistoryItem: string) {
    // Add this item to the list of history items, then save to localStorage.
    if (this.pageHistory[this.pageHistory.length - 1]?.url !== newHistoryItem) {
      this.pageHistory.push({ url: newHistoryItem, occurredAt: Date.now() })
    }
    writePageHistoryToStorage(this.storageType, this.pageHistory)
    this._callback(this.pageHistory)
  }
}

export class PageHistoryHandler implements PageHistoryHandlerInterface {
  private underlyingSessionHistoryIdleTimeHandler: SessionHistoryIdleTimeHandler
  private underlyingLocalHistoryChangeHandler: LocalHistoryChangeHandler

  constructor(options: {
    sessionAfterSecondPageVisitAndIdleCallback: () => void | Promise<void>
    localChangeCallback: (pageHistory: Array<{ url: string; occurredAt: number }>) => void
  }) {
    this.underlyingSessionHistoryIdleTimeHandler = new SessionHistoryIdleTimeHandler(
      options.sessionAfterSecondPageVisitAndIdleCallback
    )
    this.underlyingLocalHistoryChangeHandler = new LocalHistoryChangeHandler(
      options.localChangeCallback
    )
  }

  public get sessionLength() {
    return this.underlyingSessionHistoryIdleTimeHandler.sessionLength
  }

  public push(newHistoryItem: string) {
    this.underlyingSessionHistoryIdleTimeHandler.push(newHistoryItem)
    this.underlyingLocalHistoryChangeHandler.push(newHistoryItem)
  }
}
