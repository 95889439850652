import { PopulationType } from './types'

export function getPopulationTypeFromStorage(): PopulationType {
  const populationType = safeGetItem('caas.populationType')
  return isPopulationType(populationType) ? populationType : PopulationType.SAMPLED_OUT
}

export function getSessionTokenFromStorage(): string | undefined {
  return safeGetItem('caas.sessionToken')
}

export function getSessionIdFromStorage(): string | undefined {
  const sessionToken = getSessionTokenFromStorage()
  return sessionToken && window.atob(sessionToken.split('.')[1])
}

export function getConsumerIdFromStorage(): string | undefined {
  return safeGetItem('caas.consumerId')
}

export function getLeadIdFromStorage(): string | undefined {
  return safeGetItem('caas.leadId')
}

export function getShouldBypassAllowlistFlagsFromLocalStorage(): boolean {
  if (getConsumerIdFromStorage() || safeGetItem('caas.bypassAllowlist')) {
    return true
  }
  return false
}

export function safeSetItem(key: string, value: string | null) {
  if (canUseLocalStorage()) {
    !value ? localStorage.removeItem(key) : localStorage.setItem(key, value)
  }
}

export function safeGetItem(key: string): string | undefined {
  const item = canUseLocalStorage() && localStorage.getItem(key)
  if (item) return item
}

// This is equivalent to saying `type StorageType = "opener" | "localStorage" | "sessionStorage"`,
// but conveniently lets the compiler know that indexing window with a key of `StorageType` will be of type `Storage`.
export type StorageType = {
  [K in keyof typeof window]: (typeof window)[K] extends Storage ? K : never
}[keyof typeof window] & {}

export function canUseStorage(type: StorageType) {
  const test = 'test'
  try {
    const storage = window[type] as Storage
    storage.setItem('test', test)
    const canUse = storage.getItem('test') === 'test'
    storage.removeItem('test')
    return canUse
  } catch {
    return false
  }
}

export function canUseLocalStorage() {
  return canUseStorage('localStorage')
}

export function canUseSessionStorage() {
  return canUseStorage('sessionStorage')
}

function isPopulationType(item?: string): item is PopulationType {
  const ALL_POPULATION_TYPES = Object.keys(PopulationType)
  return !!item && ALL_POPULATION_TYPES.includes(item)
}

const PAGE_HISTORY_STORAGE_KEY = 'caas.pageHistory'

export function readPageHistoryFromStorage(
  storageType: StorageType
): Array<{ url: string; occurredAt: number }> {
  let history: Array<{ url: string; occurredAt: number }> = []
  if (canUseStorage(storageType)) {
    const item = window[storageType].getItem(PAGE_HISTORY_STORAGE_KEY)
    const parsed = item && JSON.parse(item)
    if (parsed && Array.isArray(parsed)) {
      history = parsed.map(entry => {
        // A previous version of this code only stored a list of urls, not objects.
        if (typeof entry === 'string') {
          return { url: entry, occurredAt: Date.now() }
        }
        // A previous version of this code had a misspelling (`occuredAt` vs. `occurredAt`).
        return 'occuredAt' in entry ? { url: entry.url, occurredAt: entry.occuredAt } : entry
      }) as Array<{ url: string; occurredAt: number }>
    }
  }
  return history
}

export function writePageHistoryToStorage(
  storageType: StorageType,
  pageHistory: Array<{ url: string; occurredAt: number }>
) {
  if (canUseSessionStorage()) {
    window[storageType].setItem(PAGE_HISTORY_STORAGE_KEY, JSON.stringify(pageHistory))
  }
}
