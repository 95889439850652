import {
  isMobile,
  addScrollLock,
  removeScrollLock,
  syncMobileDocumentHeightToCssProperty
} from './scrollLock'
import { recordSale } from './transaction'
import { PartnerId, PublisherId, TenantId } from './partnerId'
import {
  WidgetMode,
  CuratedFrameConstructorOptions,
  CuratedFrameInterface,
  TransactionData,
  CuratedSettings,
  CuratedPartnerId,
  CtaTrackingProperties,
  ConnectPartnerTrackingEventData,
  WidgetBlockedReason
} from './types'
import {
  getCanaryCookie,
  getGoogleAnalyticsCookie,
  getSessionCookie,
  setCanaryCookie,
  setSessionCookie
} from './cookie'
import {
  safeGetItem,
  safeSetItem,
  getShouldBypassAllowlistFlagsFromLocalStorage
} from './localStorage'
import { PageHistoryHandler, PageHistoryHandlerInterface } from './PageHistoryHandler'
import { FrameObservers } from './FrameObservers'
import { injectComponents } from './markupInjection'
import { isUrlAllowlisted, isUrlBlocklisted, isCurrentUrlOnInitiallyHideList } from './allowlist'
import debounce from 'lodash/debounce'
import { getHandleReadySideEffectsForPartner } from './sideEffects'
import { getIsAdBlockerDetected } from './getIsAdBlockerDetected'
import { FloatingCTA } from './floatingCta'
import { getThemeForPartner } from './theme'
import {
  IframeElementCSSClass,
  ParentPostMessageClient,
  RollbarCapturable,
  ToParentMessage,
  WidgetTheme,
  WIDGET_VERSION
} from 'lib'
import { getFloatingCtaMinimizationStrategyForPartner } from './floatingCta/minimizationStrategy'
import { StalePageRefresher } from './StalePageRefresher'

const ROOT_ID = 'curated-connect-iframe'

const IFRAME_SRCS: Record<WidgetMode, string> = {
  prod: 'https://connect.curated.com',
  staging: 'https://connect.curated-staging.com',
  'local-staging': 'https://connect.local.curated-staging.com',
  dev: 'https://connect.curated-dev.com'
}

class CuratedFrame implements CuratedFrameInterface {
  private tenantId: TenantId | null = null
  private publisherId: PublisherId | null = null
  private mode: WidgetMode
  private root: HTMLDivElement
  private iframe: HTMLIFrameElement
  private injectedComponents: HTMLDivElement[] = []
  private href: string
  /**
   * This selector is used to identify elements that should trigger the widget to flip sides when intersecting.
   * It is used by the `FrameObservers` class.
   */
  private intersectionObservationTargetSelector = '[data-curated-avoid]'
  private pageHistoryHandler: PageHistoryHandlerInterface
  private frameObservers: FrameObservers
  private scrollYWhenOpened?: number
  private overrideParamName = 'caasOverride'
  private forceTraceParamName = 'caasForceTrace'
  private reengagmentTokenParamName = 'rgt'
  private scrollEventTarget: EventTarget
  private debounceWait = 50
  private latestScrollTop: number
  private sideEffectCleanupFunctions: Set<() => void> = new Set()
  private onEventTrackingRequested?: (eventData: ConnectPartnerTrackingEventData) => void
  /** By default, returns the partner's Google Analytics client ID. */
  private getExternalClientInstanceId: () => string | null = getGoogleAnalyticsCookie
  private isFloatingCtaEnabled = false
  private floatingCta?: FloatingCTA
  private isExpertMessagePreviewAllowed = false
  /**
   * If true, the avatar rating will be persistently displayed in collapsed and expanded states, and the rotating rating stars will be hidden.\
   * _Only applies to the floating CTA._ \
   * Controlled by the `caas-floating-expert-rating` experiment.
   */
  private isAvatarRatingPersistent = true
  /**
   * The variation of the CTA headings to be used.\
   * Controlled by the `caas-floating-cta-headings` experiment.
   */
  public ctaHeadingsVariation: 'control' | 'shop' | 'gear' = 'control'
  private onWidgetLoaded?: () => void
  private onWidgetLoadRejected?: (reason: string) => void
  private postMessageClient: ParentPostMessageClient
  private stalePageRefresher: StalePageRefresher
  public running: boolean

  constructor(
    { tenantId, publisherId }: CuratedPartnerId,
    options: CuratedFrameConstructorOptions = {}
  ) {
    const {
      mode = 'prod',
      onEventTrackingRequested,
      onWidgetLoaded,
      onWidgetLoadRejected,
      scrollEventTarget = window,
      getCustomerSessionId
    } = options
    this.tenantId = (tenantId || this.tenantId) as TenantId | null
    this.publisherId = (publisherId || this.publisherId) as PublisherId | null
    this.onEventTrackingRequested = onEventTrackingRequested
    this.onWidgetLoaded = onWidgetLoaded
    this.onWidgetLoadRejected = onWidgetLoadRejected
    this.getExternalClientInstanceId = getCustomerSessionId || this.getExternalClientInstanceId
    this.mode = mode
    this.href = document.location.href
    this.root = this.createRoot()
    this.iframe = this.createIframe()
    this.scrollEventTarget = scrollEventTarget
    this.latestScrollTop = this.getScrollTop()
    this.running = false
    this.pageHistoryHandler = new PageHistoryHandler({
      sessionAfterSecondPageVisitAndIdleCallback: () => {
        this.postMessageClient.postMessage('was-idle-after-second-page-view', undefined)
      },
      localChangeCallback: pageHistory => {
        this.postMessageClient.postMessage('page-history-updated', pageHistory)
      }
    })
    this.frameObservers = new FrameObservers({
      intersectionCallback: areAnyIntersecting => {
        if (areAnyIntersecting) {
          // If the widget is intersecting any elements that match the `intersectionTargets`,
          //   then align it to the opposite side of the page.
          const flippedAlignment = this.theme?.alignment === 'left' ? 'right' : 'left'
          this.handleAlignment(flippedAlignment)
        } else {
          // Otherwise, align it to the side specified by the theme (or right by default)
          this.handleAlignment(this.theme?.alignment || 'right')
        }
      },
      mutationCallback: () => {
        if (this.href !== document.location.href) {
          this.emitParentURLMessage(document.location.href)
          this.pageHistoryHandler.push(document.location.origin + document.location.pathname)
        }
      },
      intersectionTargets: () => {
        return document.querySelectorAll(this.intersectionObservationTargetSelector)
      },
      mutationTarget: () => {
        return document.body
      }
    })

    this.postMessageClient = new ParentPostMessageClient(this.iframe.src)
    this.registerPostMessageListeners()
    this.stalePageRefresher = new StalePageRefresher({
      onReloadFrame: this.handleReload.bind(this)
    })
  }

  private get theme(): WidgetTheme {
    return getThemeForPartner(this.partnerId)
  }

  private registerPostMessageListeners() {
    this.postMessageClient.registerDataResponse('session-token', () => {
      return safeGetItem('caas.sessionToken') || null
    })
    this.postMessageClient.registerDataResponse('session-token-cookie', () => {
      return getSessionCookie()
    })
    this.postMessageClient.registerDataResponse('client-id', () => {
      return this.getExternalClientInstanceId()
    })
    this.postMessageClient.registerDataResponse('parent-url', () => document.location.href)
    this.postMessageClient.register('widget-ready', this.handleReady)
    this.postMessageClient.register('iframe-classlist-modified', this.handleIframeClasslistModified)
    this.postMessageClient.register('reload-requested', reloadTarget => {
      this.handleReload(reloadTarget === 'iframe')
    })
    this.postMessageClient.register('population-type-changed', populationType => {
      safeSetItem('caas.populationType', populationType)
    })
    this.postMessageClient.register('session-token-changed', sessionToken => {
      // Store the session token in localStorage.
      safeSetItem('caas.sessionToken', sessionToken)
      // Store the session token in a JS cookie.
      setSessionCookie(sessionToken)
    })
    this.postMessageClient.register('consumer-id-changed', consumerId => {
      safeSetItem('caas.consumerId', consumerId)
    })
    this.postMessageClient.register('lead-id-changed', leadId => {
      safeSetItem('caas.leadId', leadId)
    })
    this.postMessageClient.register('component-injection-requested', this.showInjectedComponents)
    this.postMessageClient.register('event-tracking-requested', eventData => {
      this.onEventTrackingRequested?.(eventData)
    })
    this.postMessageClient.register('expert-for-floating-cta-set', expert => {
      if (this.floatingCta) {
        this.floatingCta.expert = expert
      }
    })
    this.postMessageClient.register(
      'expert-message-preview-status-changed',
      ({ isExpertMessagePreviewAllowed }) => {
        this.isExpertMessagePreviewAllowed = isExpertMessagePreviewAllowed
        if (this.floatingCta) {
          this.floatingCta.fireOnClickFromMinimizedState = this.isExpertMessagePreviewAllowed
        }
      }
    )
    this.postMessageClient.register('floating-cta-status-changed', ({ isFloatingCtaEnabled }) => {
      this.isFloatingCtaEnabled = isFloatingCtaEnabled
      if (!isFloatingCtaEnabled) {
        this.destroyFloatingCta()
      }
    })

    this.postMessageClient.register('floating-cta-rating-variation', ({ variation }) => {
      this.isAvatarRatingPersistent = variation === 'avatar'

      if (this.floatingCta) {
        this.floatingCta.isAvatarRatingPersistent = this.isAvatarRatingPersistent
      }
    })

    this.postMessageClient.register('floating-cta-headings-variation', ({ variation }) => {
      this.ctaHeadingsVariation = variation

      if (this.floatingCta) {
        this.floatingCta.ctaHeadingsVariation = this.ctaHeadingsVariation
      }
    })
    // –– The below are only for token storage debugging, remove after the most reliable token storage mechanism is determined ––
    // The connect-app will send this message each time it loads.
    // The parent site responds by setting a timestamp in localStorage and a JS cookie.
    this.postMessageClient.register('canary-values-set', ({ timestamp }) => {
      // Only write new values to localStorage/cookies if they don't already exist.
      if (!safeGetItem('caas.canary')) {
        safeSetItem('caas.canary', timestamp)
      }
      if (!getCanaryCookie()) {
        setCanaryCookie(timestamp)
      }
    })
    /** Returns the canary value from localStorage. */
    this.postMessageClient.registerDataResponse('canary-storage', () => {
      return safeGetItem('caas.canary') || null
    })
    /** Returns the canary value from JS cookies. */
    this.postMessageClient.registerDataResponse('canary-cookie', () => {
      return getCanaryCookie()
    })
  }

  // INITIALIZERS:
  private createRoot = () => {
    const root = document.createElement('div')
    root.id = ROOT_ID
    document.body.appendChild(root)
    return root
  }
  private generateIframeSrc = () => {
    const search = new URLSearchParams({
      // The connect-app imports WIDGET_VERSION from the '@deal/connect-widget' package (the lib directory in this repo).
      // If the browser-cached version of this script doesn't send the same version as is imported in the connect-app,
      // the connect-app will refuse to load. This ensures breaking changes to the postMessage API don't break the experience.
      WIDGET_VERSION: WIDGET_VERSION,
      isMobile: `${isMobile()}`
    })
    if (this.tenantId) {
      search.set('tenantId', this.tenantId)
    } else if (this.publisherId) {
      // Publishers all use the "Curated" (first-party) tenant.
      search.set('tenantId', TenantId.CURATED)
      search.set('publisherId', this.publisherId)
    }
    if (this.hasOverrideParam) {
      search.set(this.overrideParamName, 'true')
    }

    // Support for the deprecated `caasExperimentOverrides` parameter is maintained for backwards compatibility.
    const deprecatedExperimentOverridesParamValue = this.getParamValue('caasExperimentOverrides')
    if (deprecatedExperimentOverridesParamValue) {
      search.set('experimentOverrides', deprecatedExperimentOverridesParamValue)
    }

    // The `experimentOverrides` parameter overrides the deprecated `caasExperimentOverrides` parameter.
    const experimentOverridesParamValue = this.getParamValue('experimentOverrides')
    if (experimentOverridesParamValue) {
      search.set('experimentOverrides', experimentOverridesParamValue)
    }

    const reengagementTokenParamValue = this.getParamValue(this.reengagmentTokenParamName, true)
    if (reengagementTokenParamValue) {
      search.set(this.reengagmentTokenParamName, reengagementTokenParamValue)
    }
    const forceTraceParamValue = this.getParamValue(this.forceTraceParamName, true)
    if (forceTraceParamValue) {
      search.set(this.forceTraceParamName, forceTraceParamValue)
    }
    if (this.onEventTrackingRequested) {
      search.set('connectPartnerTrackingEnabled', 'true')
    }
    if (this.getExternalClientInstanceId()) {
      search.set('externalClientInstanceIdEnabled', 'true')
    }
    return IFRAME_SRCS[this.mode] + `?${search.toString()}`
  }
  private createIframe = () => {
    const iframe = document.createElement('iframe')
    iframe.src = this.generateIframeSrc()
    iframe.classList.add('frame', 'widget-hidden')
    iframe.allow = 'clipboard-write;microphone;camera'
    return iframe
  }
  private addEventListeners = () => {
    window.addEventListener('resize', this.handleResize)
    this.scrollEventTarget.addEventListener('scroll', this.handleScroll)
    window.addEventListener('unload', this.handleUnload)
  }

  // PUBLIC METHODS:
  public loadWidget() {
    this.loadWidgetPromise()
      .then(() => this.onWidgetLoaded?.())
      .catch(rejectReason => {
        this.destroyFloatingCta()
        this.onWidgetLoadRejected?.(rejectReason)
      })
  }
  /** Returns a Promise that is resolved when the connect-app loads.
   * The Promise is rejected after a timeout or if the connect-app decides not to load. */
  private loadWidgetPromise = (): Promise<void> => {
    if (this.canLoadWidget) {
      this.stalePageRefresher.monitor()
      this.addEventListeners()
      this.root.appendChild(this.iframe)
      this.running = true
      this.handleAlignment(this.theme?.alignment || 'right')
      this.frameObservers.connect()
      this.createInjectedComponents()
      if (this.postMessageClient.isTargetWindowClosed) {
        this.postMessageClient.awaitHandshake()
      }
      // The promise is resolved if the connect-app loads following this
      return new Promise((resolve, reject) => {
        let timeout: NodeJS.Timeout
        let handleResponseFromConnectApp: (e: MessageEvent<ToParentMessage>) => void
        const handleCleanup = () => {
          window.removeEventListener('message', handleResponseFromConnectApp)
          clearTimeout(timeout)
        }
        handleResponseFromConnectApp = e => {
          if (e.data.type === 'widget-ready') {
            handleCleanup()
            return resolve()
          }
          if (e.data.type === 'widget-blocked') {
            handleCleanup()
            let rejectReason: string = e.data.value.reason
            if (e.data.value.reason === 'ERROR' && e.data.value.errorMessage) {
              // If reason is "ERROR", an `errorMessage` should be included.
              rejectReason += `: ${e.data.value.errorMessage}`
            }
            return reject(rejectReason)
          }
        }
        window.addEventListener('message', handleResponseFromConnectApp)
        timeout = setTimeout(() => {
          handleCleanup()
          reject(WidgetBlockedReason.REQUEST_TIMEOUT)
          // RFC: Is 10s reasonable?
        }, 10_000)
      })
    } else {
      return Promise.reject(WidgetBlockedReason.URL_NOT_ALLOWED)
    }
  }
  public expand = (cta: CtaTrackingProperties = { type: 'widget' }) => {
    if (!this.running) {
      this.loadWidget()
    }
    this.postMessageClient.postMessage('state-change-requested', {
      command: this.isExpertMessagePreviewAllowed ? 'PREVIEW' : 'EXPAND',
      cta
    })
    this.floatingCta?.hide()
  }
  public collapse = () => {
    if (!this.running) {
      this.loadWidget()
    }
    this.postMessageClient.postMessage('state-change-requested', { command: 'COLLAPSE' })
    if (this.isFloatingCtaEnabled && this.floatingCta) {
      this.floatingCta.show()
    }
  }
  public hide = (options: { unmountIframe: boolean } = { unmountIframe: true }) => {
    this.postMessageClient.postMessage('state-change-requested', { command: 'HIDE' })
    if (options.unmountIframe) {
      setTimeout(this.unloadWidget.bind(this), 500)
    }
    this.destroyFloatingCta()
  }

  private unloadWidget() {
    // Remove the iframe element and the floating CTA (if it exists) from the DOM.
    if (this.root.contains(this.iframe)) {
      this.root.removeChild(this.iframe)
    }
    this.destroyFloatingCta()
    this.hideInjectedComponents()
    this.stalePageRefresher.pause()
    this.running = false
  }

  private destroyFloatingCta() {
    if (this.floatingCta) {
      this.floatingCta.destroy()
      delete this.floatingCta
    }
  }

  public recordSale = (transaction: TransactionData) => {
    import('./analytics')
      .then(module => {
        const trackingClient = module.configureTrackingClient({
          tenantId: this.tenantId,
          publisherId: this.publisherId,
          mode: this.mode
        })
        return recordSale(
          { tenantId: this.tenantId!, mode: this.mode, transaction },
          message => {
            this.captureRollbar({ level: 'warning', message })
          },
          data => {
            trackingClient.track(new module.ConnectPartnerTransactionRecordedEvent(data))
          }
        )
      })
      .then(console.log)
      .catch(message => {
        if (message && typeof message === 'string') {
          this.captureRollbar({ level: 'error', message })
        }
      })
  }
  public update = (settings?: CuratedSettings) => {
    if (settings) {
      this.tenantId = (settings.tenantId || this.tenantId) as TenantId | null
      this.publisherId = (settings.publisherId || this.publisherId) as PublisherId | null
      this.mode = settings.mode || this.mode
      this.iframe = this.createIframe()
      if (this.running) {
        this.loadWidget()
      } else {
        this.hide()
      }
    }
    return this
  }
  private createInjectedComponents = () => {
    this.injectedComponents = injectComponents(this.partnerId)
  }
  public showInjectedComponents = async () => {
    if (!this.injectedComponents?.length) {
      // If they don't exist already, create the injected components and wait 0.5s before proceeding.
      this.createInjectedComponents()
      await new Promise(resolve => setTimeout(resolve, 500))
    }
    // Show all components that were injected.
    this.injectedComponents.forEach(component => {
      component.classList.add('visible')
    })
  }
  private hideInjectedComponents = () => {
    this.injectedComponents.forEach(component => {
      component.classList.remove('visible')
    })
  }

  private handleReady = (
    options: { isExpanded?: boolean; isFloatingCta?: boolean } = {
      isExpanded: false,
      isFloatingCta: false
    }
  ) => {
    // Check for ad blockers. If detected, alert the application. Only do this for publishers.
    if (this.publisherId) {
      getIsAdBlockerDetected().then(isDetected => {
        if (isDetected) {
          this.postMessageClient.postMessage('ad-blocker-detected', undefined)
        }
      })
    }

    // Get all side-effects, and store their cleanup functions in the set.
    for (const sideEffect of getHandleReadySideEffectsForPartner(this.partnerId)) {
      // Call the side-effect here, and get the cleanup function.
      const sideEffectCleanupFunction = sideEffect()
      // If the side-effect returns a cleanup function, add it to the set.
      if (sideEffectCleanupFunction) {
        this.sideEffectCleanupFunctions.add(sideEffectCleanupFunction)
      }
    }
    this.emitParentURLMessage()
    this.pageHistoryHandler.push(document.location.origin + document.location.pathname)

    // Post `theme` to the application.
    if (this.theme) {
      this.postMessageClient.postMessage('theme-changed', this.theme)
    }

    this.isFloatingCtaEnabled = options.isFloatingCta ?? this.isFloatingCtaEnabled
    // if the floating CTA is enabled and doesn't exist yet, initialize it.
    if (this.isFloatingCtaEnabled && !this.floatingCta) {
      this.floatingCta = new FloatingCTA({
        minimizationStrategy: getFloatingCtaMinimizationStrategyForPartner(this.partnerId),
        onClick: () => this.expand({ type: 'widget', name: 'floating-cta' }),
        fireOnClickFromMinimizedState: this.isExpertMessagePreviewAllowed,
        theme: this.theme,
        isAvatarRatingPersistent: this.isAvatarRatingPersistent,
        ctaHeadingsVariation: this.ctaHeadingsVariation,
        sessionLength: this.pageHistoryHandler.sessionLength
      })
    }

    // Alert the application to render.
    if (options.isExpanded) {
      this.expand()
    } else {
      this.collapse()
    }

    // Use this flag to bypass the allowlist check when the widget loads.
    safeSetItem('caas.bypassAllowlist', 'true')
  }

  /** The `iframe-classlist-modified` message is sent by the connect-app when it's expansion state is changed */
  private handleIframeClasslistModified = (classes: {
    toAdd: IframeElementCSSClass[]
    toRemove: IframeElementCSSClass[]
  }) => {
    const shouldFrameReceiveFocus =
      classes.toAdd.includes('widget-open') || classes.toAdd.includes('widget-preview')
    if (shouldFrameReceiveFocus) {
      // If the iframe is expanding to its open or preview state,
      // then transfer focus to the child window (which hides the floating CTA).
      this.transferFocus(this.iframe)
    } else {
      // Otherwise, transfer focus to the parent window (which shows the floating CTA).
      this.transferFocus(window)
    }

    const shouldScrollLockBeApplied = classes.toAdd.includes('widget-open')
    if (shouldScrollLockBeApplied) {
      // If the iframe is expanding to its open state, then add scroll-lock.
      addScrollLock(scrollY => {
        this.scrollYWhenOpened = scrollY
      })
    } else {
      // Otherwise, reset the scroll position and remove the scroll-lock.
      removeScrollLock(() => this.scrollYWhenOpened)
      this.scrollYWhenOpened = undefined
    }

    // Add and remove the classes as specified in the message payload.
    classes.toAdd.forEach(className => {
      this.iframe.classList.add(className)
    })
    classes.toRemove.forEach(className => {
      this.iframe.classList.remove(className)
    })
  }

  private handleReload = (iframeOnly: boolean = true) => {
    if (iframeOnly) {
      if (!window.navigator.onLine) {
        // If network is disconnected, unload the iframe and wait for connectivity to resume before loading it again.
        this.unloadWidget()
        window.addEventListener('online', this.loadWidget.bind(this), { once: true })
      }
      // Unless the widget was unloaded, this operation reloads the iframe's contentWindow.
      // If it was unloaded, the html element in memory will still have an up-to-date src attribute.
      this.iframe.src = this.generateIframeSrc()
    } else {
      window.location.reload()
    }
  }
  private handleAlignment = (alignment: 'left' | 'right') => {
    this.floatingCta?.align(alignment)
    switch (alignment) {
      case 'left':
        this.iframe.classList.remove('align-right')
        this.iframe.classList.add('align-left')
        break

      case 'right':
        this.iframe.classList.remove('align-left')
        this.iframe.classList.add('align-right')
        break
    }
  }
  private transferFocus = (target: typeof window | typeof this.iframe) => {
    target.focus()
    if (target === this.iframe) {
      this.floatingCta?.hide()
      setTimeout(() => {
        target.contentWindow?.focus()
      }, 100)
    } else {
      this.floatingCta?.show()
    }
  }

  // EVENT LISTENERS:
  // Sends a message to the connect-app when scrolling up
  private handleScroll = debounce(() => {
    const scrollTop = this.getScrollTop()
    if (scrollTop < this.latestScrollTop && this.running) {
      this.postMessageClient.postMessage('scrolled-up', undefined)
    }
    this.latestScrollTop = Math.max(scrollTop, 0) // For mobile/negative scrolling
  }, this.debounceWait)

  private handleResize = debounce(() => {
    syncMobileDocumentHeightToCssProperty()
    this.postMessageClient.postMessage('page-resized', { isMobile: isMobile() })
  }, this.debounceWait)
  // Remove event listeners
  private handleUnload = () => {
    this.postMessageClient.unregister()
    window.removeEventListener('resize', this.handleResize)
    this.scrollEventTarget.removeEventListener('scroll', this.handleScroll)
    this.frameObservers.disconnect()
    this.handleSideEffectCleanup()
  }

  private captureRollbar = (value: RollbarCapturable) => {
    // The snippet that runs on our partners' sites does not hook into Rollbar, but we can report errors to the connect-app via postMessage.
    this.postMessageClient.postMessage('rollbar-error-captured', value)
  }

  private emitParentURLMessage = (newHref?: string) => {
    if (newHref) this.href = newHref
    this.postMessageClient.postMessage('parent-url-changed', this.href)
  }

  private getParamValue = (
    paramName: string,
    shouldDeleteAfter: boolean = false
  ): string | null => {
    let paramValue: string | null = null
    try {
      const url = new URL(window.location.href)
      paramValue = url.searchParams.get(paramName)
      if (shouldDeleteAfter && paramValue) {
        url.searchParams.delete(paramName)
        history.replaceState(null, '', url.toString())
      }
    } catch {
      paramValue = null
    }
    return paramValue
  }

  private getScrollTop = () => {
    if (this.scrollEventTarget === window) {
      return window.scrollY
    }
    if (this.scrollEventTarget instanceof HTMLElement) {
      return this.scrollEventTarget.scrollTop
    }
    return document.documentElement.scrollTop
  }

  private get partnerId(): PartnerId {
    return this.tenantId || this.publisherId || TenantId.CURATED
  }

  private get hasOverrideParam() {
    return this.getParamValue(this.overrideParamName) === 'true'
  }

  private get canLoadWidget() {
    if (!window.navigator.onLine) {
      // Cannot load the widget with no network connection.
      return false
    }

    if (isUrlBlocklisted(this.partnerId)) {
      // If the URL is blocklisted, then the widget cannot load under any circumstances.
      return false
    }

    if (this.hasOverrideParam || this.iframeSearchParams.has(this.reengagmentTokenParamName)) {
      // If using a reengagement link or the `caasOverride` parameter, then the widget can load.
      return true
    }

    if (getShouldBypassAllowlistFlagsFromLocalStorage()) {
      // If there's a consumer ID and/or the user saw or engaged with the widget already, then ignore the URL.
      // We can always load the widget for identified consumers and those who "rolled treatment" least once.
      return true
    }

    // If not, then load only on URLs that are allowlisted and not on the initially hide list.
    return isUrlAllowlisted(this.partnerId) && !isCurrentUrlOnInitiallyHideList(this.partnerId)
  }

  private get iframeSearchParams() {
    try {
      return new URL(this.iframe.src).searchParams
    } catch {
      return new URLSearchParams()
    }
  }

  private handleSideEffectCleanup = () => {
    for (const cleanupFn of this.sideEffectCleanupFunctions) {
      cleanupFn()
    }
  }
}

export function createCuratedFrame(
  tenantId?: string,
  publisherId?: string,
  options?: CuratedFrameConstructorOptions
): CuratedFrame {
  if (tenantId) {
    return new CuratedFrame({ tenantId }, options)
  }
  if (publisherId) {
    return new CuratedFrame({ publisherId }, options)
  }
  throw new Error('Cannot instantiate CuratedFrame class without a valid tenant/publisher ID.')
}

export default CuratedFrame
