import type {
  TransactionData,
  NormalizedTransactionData,
  TransactionLineItem,
  WidgetMode
} from './types'
import {
  getPopulationTypeFromStorage,
  getSessionTokenFromStorage,
  getSessionIdFromStorage,
  getConsumerIdFromStorage,
  getLeadIdFromStorage
} from './localStorage'
import omit from 'lodash/omit'

interface RecordSaleArgs {
  tenantId: string
  mode: WidgetMode
  transaction: any
}

export async function recordSale(
  { transaction, ...inputArgs }: RecordSaleArgs,
  onWarning: (warningMessage: string) => void,
  onTransactionRecorded: (data: NormalizedTransactionData) => void
): Promise<Response> {
  try {
    transaction = isValidTransaction(transaction)
  } catch (e) {
    // Capture the error, but don't block further execution of this function.
    if (e instanceof Error) {
      onWarning(e.message)
    }
  }
  return normalizeTransactionData(transaction).then(data => {
    onTransactionRecorded(data)
    const body = JSON.stringify(data)
    const input = buildTransactionRequestInput(inputArgs)
    const headers = buildTransactionRequestHeaders(getSessionTokenFromStorage())
    return fetch(input, { method: 'POST', headers, body })
  })
}

async function normalizeTransactionData(
  transaction: TransactionData
): Promise<NormalizedTransactionData> {
  const { rawEmail, rawPhoneNumber, ...partialNormalizedData } = transaction
  const data: NormalizedTransactionData = {
    ...partialNormalizedData,
    populationType: getPopulationTypeFromStorage(),
    sessionId: getSessionIdFromStorage(),
    consumerId: getConsumerIdFromStorage(),
    leadId: getLeadIdFromStorage()
  }
  if (rawEmail) {
    try {
      data.hashedEmail = await saltAndEncodeContact(normalizeEmail(rawEmail))
    } catch (e) {
      if (e instanceof Error) {
        return Promise.reject(e.message)
      }
    }
  }
  if (rawPhoneNumber) {
    try {
      data.hashedPhoneNumber = await saltAndEncodeContact(normalizePhoneNumber(rawPhoneNumber))
    } catch (e) {
      if (e instanceof Error) {
        return Promise.reject(e.message)
      }
    }
  }
  return data
}

const TRANSACTION_SCHEMA: Record<
  keyof TransactionData,
  { validator: (value?: any) => boolean; error: string }
> = {
  transactionId: {
    validator: value => typeof value === 'string' && value.length > 0,
    error: '`transactionId` is required, and must be a string.'
  },
  timeOfSale: {
    validator: value => typeof value === 'string' && !isNaN(Date.parse(value)),
    error: '`timeOfSale` is required, and must be parse-able as a Date.'
  },
  amount: {
    validator: value => typeof value === 'string' && !isNaN(parseFloat(value)),
    error: '`amount` is required, and must be parse-able as a floating point number.'
  },
  currency: {
    validator: value => value == null || value === 'USD',
    error: "`currency` is not required, but must be 'USD' if it is included."
  },
  rawEmail: {
    validator: value => value == null || (typeof value === 'string' && value.length > 0),
    error: '`rawEmail` is not required, but must be a string if it is included.'
  },
  rawPhoneNumber: {
    validator: value => value == null || (typeof value === 'string' && value.length > 0),
    error: '`rawPhoneNumber` is not required, but must be a string if it is included.'
  },
  lineItems: {
    validator: value => Array.isArray(value) && value.length > 0 && value.every(isValidLineItem),
    error: '`lineItems` is required, and must be a non-empty array of valid line items.'
  }
}

// @ts-ignore
function isValidTransaction(transaction: any): transaction is TransactionData {
  const errors: string[] = []
  if (typeof transaction !== 'object') {
    // Validate that the `transaction` is an object.
    errors.push('`transaction` must be an object.')
  } else {
    // Validate that the `transaction` object contains only valid keys.
    const unknownKeys = Object.keys(omit(transaction, Object.keys(TRANSACTION_SCHEMA)))
    if (unknownKeys.length > 0) {
      errors.push(`Unknown key(s) in \`transaction\` object: ${unknownKeys.join(', ')}`)
    } else {
      // Validate that all the `transaction` object's values match the schema.
      for (const [key, { validator, error }] of Object.entries(TRANSACTION_SCHEMA)) {
        // For each key in the schema, find the value for that key in the `transaction` object.
        let value: TransactionData[keyof TransactionData] = undefined
        if (key in transaction) {
          value = transaction[key]
        }
        // Use the `validator` function from the schema to validate.
        if (!validator(value)) {
          errors.push(error)
        }
      }
    }
  }
  if (errors.length > 0) {
    throw new TypeError('Could not record sale—\n• ' + errors.join('\n• '))
  }
  return transaction
}

function isValidLineItem(lineItem: any): lineItem is TransactionLineItem {
  // Validate that the provided `lineItem` is an object.
  if (typeof lineItem !== 'object') {
    return false
  }
  let validated = true
  // `subtotal` is not required, but must be a string if it is included.
  if ('subtotal' in lineItem) {
    validated = validated && (typeof lineItem.subtotal === 'string' || lineItem.subtotal === null)
  }
  // `quantity` is not required, but must be an integer if it is included.
  if ('quantity' in lineItem) {
    validated =
      validated &&
      ((typeof lineItem.quantity === 'number' && Number.isInteger(lineItem.quantity)) ||
        lineItem.quantity === null)
  }
  // Either `gtin` or `brand` + `mpn` is required.
  if ('gtin' in lineItem) {
    validated = validated && typeof lineItem.gtin === 'string'
  } else if ('mpn' in lineItem && 'brand' in lineItem) {
    validated = validated && typeof lineItem.mpn === 'string' && typeof lineItem.brand === 'string'
  } else {
    validated = false
  }
  return validated
}

export async function saltAndEncodeContact(contact: string): Promise<string | undefined> {
  try {
    const saltedContact = contact + '+curated'
    const utf8 = new TextEncoder().encode(saltedContact)
    const rawHash = await crypto.subtle.digest('SHA-256', utf8).then(buffer => {
      return new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
    })
    return window.btoa(rawHash)
  } catch {
    // Error is handled by the caller of this function: `getRequestBodyFromTransaction`.
    throw new Error('Unable to hash email address/phone number, protocol must be https')
  }
}

export function normalizeEmail(email: string): string {
  // Divide email into exactly two parts.
  const emailParts = email.trim().toLowerCase().split('@')
  if (emailParts.length !== 2) {
    // Error is handled by the caller of this function: `getRequestBodyFromTransaction`.
    throw new Error('Email address is not properly formatted')
  }
  const [name, domain] = emailParts as [string, string]
  let normalizedName = name
  // If `domain` part is gmail, remove '.' & anything after a '+' from the `name` part.
  if (domain === 'gmail.com') {
    normalizedName = normalizedName.replace('.', '')
    normalizedName = normalizedName.split('+')[0]
  }
  return `${normalizedName}@${domain}`
}

export function normalizePhoneNumber(phoneNumber: string): string {
  // Remove all non-numberic/non-plus chars.
  let normalizedPhoneNumber = phoneNumber.trim().replace(/\D+/g, '')
  // A valid phone number (+country code) is between 10 & 14 numeric/plus chars.
  if (normalizedPhoneNumber.length < 10 || normalizedPhoneNumber.length > 14) {
    // Error is handled by the caller of this function: `getRequestBodyFromTransaction`.
    throw new Error('Phone number is not properly formatted')
  }
  // Replace '00' with a plus sign.
  normalizedPhoneNumber = normalizedPhoneNumber.replace(/^00/, '+')
  // If phone number starts with '1', add a plus sign.
  if (normalizedPhoneNumber.match(/^1/)) {
    normalizedPhoneNumber = '+' + normalizedPhoneNumber
  }
  // If phone number doesn't start with a '+', add '+1' (US country code)
  if (!normalizedPhoneNumber.match(/^\+/)) {
    normalizedPhoneNumber = '+1' + normalizedPhoneNumber
  }
  return normalizedPhoneNumber
}

function getApiHost(mode: WidgetMode): string {
  switch (mode) {
    case 'dev':
      return 'api.curated-dev.com'

    case 'local-staging':
    case 'staging':
      return 'api.curated-staging.com'

    case 'prod':
      return 'api.curated.com'
  }
}

function buildTransactionRequestInput({
  tenantId,
  mode
}: Omit<RecordSaleArgs, 'transaction'>): string {
  return `https://${getApiHost(mode)}/connect/${tenantId}/transactions`
}

function buildTransactionRequestHeaders(sessionToken?: string): HeadersInit {
  return {
    ...{ 'Content-Type': 'application/json' },
    ...(!sessionToken ? {} : { 'X-Deal-Sn': sessionToken })
  }
}
