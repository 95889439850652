import type { InjectedComponent } from './types'
import {
  callawayHomepageBannerComponent,
  callawayDriverSrpBannerComponent
} from './components/callaway'
import { sbpInlineCtaComponent } from './components/snowboardingprofiles'
import compact from 'lodash/compact'

const bluxomeGoodsTestComponent: InjectedComponent = {
  ...callawayHomepageBannerComponent,
  selector: process.env.NODE_ENV === 'development' ? 'body > :first-child' : '#MainContent'
}

export function injectComponents(partnerId: string) {
  if (!(partnerId in PARTNER_ID_TO_COMPONENT_ARGS)) {
    return []
  }
  const components = PARTNER_ID_TO_COMPONENT_ARGS[partnerId]
    .flatMap(createComponents)
    .map(([beforeElement, component]) => {
      // If no element was found using this component's "selector", then it's a no-op.
      if (!beforeElement) return null
      // Inserting the component into the DOM here is safe because the components are created with initial visibility: hidden.
      beforeElement.insertAdjacentElement('beforebegin', component)
      return component
    })
  return compact(components)
}

const PARTNER_ID_TO_COMPONENT_ARGS: Record<string, InjectedComponent[]> = {
  // Bluxome Goods:
  AgAAAdQADZzMXCB9Trim0bWXv4whMQ: [bluxomeGoodsTestComponent],
  // Callaway:
  'AgAAAdQA_ezrEu-uOvOyVmmyaxyYYQ': [
    callawayHomepageBannerComponent,
    callawayDriverSrpBannerComponent
  ],
  // Snowboardingprofiles:
  AgAAAmwAf5joC7ZNTiWq5j2lxYPt5Q: [sbpInlineCtaComponent]
}

function createComponents({
  selector,
  allowedPathnames,
  id,
  html,
  bgSm,
  bgMd,
  bgLg
}: InjectedComponent): Array<[Element | null, HTMLDivElement]> {
  // Start with a clean slate by removing any elements that exist before this executes.
  document.querySelectorAll<HTMLElement>(`#${id}`).forEach(existingElement => {
    try {
      existingElement.remove()
    } catch {
      /* empty */
    }
  })
  let canInject = true,
    beforeElements: HTMLElement[] = []
  if (allowedPathnames) {
    const { pathname } = new URL(window.location.href)
    canInject = allowedPathnames.includes(pathname)
  }
  try {
    if (canInject) {
      beforeElements = Array.from(document.querySelectorAll<HTMLElement>(selector))
    }
  } catch {
    // No-op if this fails for some reason
    // (i.e., browser doesn't support the `:has()` selector or something like that).
  }

  return beforeElements.map(beforeElement => {
    const component = document.createElement('div')
    if (beforeElement) {
      component.id = id
      component.innerHTML = html
      if (bgSm || bgMd || bgLg) {
        component.style.setProperty('--bg-sm', `url(${bgSm!})`)
        component.style.setProperty('--bg-md', `url(${bgMd!})`)
        component.style.setProperty('--bg-lg', `url(${bgLg!})`)
      }
    }
    return [beforeElement, component]
  })
}
