import { WidgetTheme, DEFAULT_WIDGET_THEME } from 'lib'
import { PartnerId } from './partnerId'

const PARTNER_THEMES: { [K in PartnerId]?: WidgetTheme } = {
  [PartnerId.BLUXOME_GOODS]: DEFAULT_WIDGET_THEME,
  [PartnerId.CALLAWAY]: {
    font: 'Roboto Condensed',
    backgroundColor: '#1a1a1a',
    textColor: '#ffffff'
  },
  [PartnerId.CANGSHAN]: {
    font: 'Mulish',
    backgroundColor: '#000000',
    textColor: '#ffffff',
    ctaBackgroundColor: '#e31e25',
    ctaTextColor: '#ffffff'
  },
  [PartnerId.CURATED]: DEFAULT_WIDGET_THEME,
  [PartnerId.NIDECKER]: {
    font: 'Open Sans',
    backgroundColor: '#232323',
    textColor: '#ffffff',
    ctaBackgroundColor: '#432889',
    ctaTextColor: '#ffffff'
  },
  [PartnerId.GOLF_LINK]: DEFAULT_WIDGET_THEME,
  [PartnerId.GOLFER_GEEKS]: {
    font: 'Roboto Condensed',
    backgroundColor: '#bc0c1f',
    textColor: '#ffffff',
    bottomOffsetDesktop: '100px',
    bottomOffsetMobile: '60px'
  },
  [PartnerId.SNOWBOARDING_PROFILES]: {
    font: 'Oswald',
    backgroundColor: '#000000',
    textColor: '#ffffff',
    ctaBackgroundColor: '#4194d0',
    ctaTextColor: '#ffffff',
    bottomOffsetDesktop: '98px'
  },
  [PartnerId.SNOWBRAINS]: {
    font: 'FT Polar',
    backgroundColor: '#c81212',
    textColor: '#ffffff',
    bottomOffsetDesktop: '36px',
    bottomOffsetMobile: '60px'
  },
  [PartnerId.THE_GOOD_RIDE]: DEFAULT_WIDGET_THEME
}

export function getThemeForPartner(partnerId: PartnerId): WidgetTheme {
  return PARTNER_THEMES[partnerId] || DEFAULT_WIDGET_THEME
}
