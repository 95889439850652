const ONE_HOUR_IN_MS = 3_600_000

// Adaptation of @deal/refresh-stale-page-js that accepts a callback to reload the iframe app.
export class StalePageRefresher {
  private hiddenAt?: Date
  private onReloadFrame?: () => void

  constructor(args: { onReloadFrame: () => void }) {
    this.onReloadFrame = args.onReloadFrame
  }

  public monitor() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange, false)
  }

  public pause() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange, false)
  }

  private handleVisibilityChange() {
    if (document.hidden) {
      this.hiddenAt = new Date()
    } else if (this.hiddenAt) {
      const msInBackground = new Date().getTime() - this.hiddenAt.getTime()
      if (msInBackground > ONE_HOUR_IN_MS) {
        this.onReloadFrame?.()
      }
    }
  }
}
