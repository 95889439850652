import { convertThemeToStyleValue, WidgetTheme } from '../../../lib'
import { interact, InteractEvent, PointerEvent } from './interact'
import { RecommendedExpertForFloatingCta } from '../types'
import { canUseSessionStorage } from '../localStorage'
import { isMobile } from '../scrollLock'
/* Importing asset as a string using the `?raw` suffix.
 * https://vitejs.dev/guide/assets#explicit-url-imports
 * @ts-ignore */
import ctaHtml from './cta.html?raw'
import starIcon from './star.svg'
import {
  FloatingCTAMinimizationStrategy,
  MINIMIZATION_IDLE_TIME_IN_SECONDS
} from './minimizationStrategy'
import './styles.css'

interface FloatingCTAConstructorArguments {
  minimizationStrategy: FloatingCTAMinimizationStrategy
  /**
   * Callback to be fired when the CTA is clicked.\
   * _Note: This does not apply to clicks to the avatar when maximized._
   */
  onClick: () => void
  /**
   * If true, the `onClick` callback will be fired when the CTA is clicked while minimized.
   */
  fireOnClickFromMinimizedState: boolean
  theme: WidgetTheme
  /**
   * If true, the avatar rating will be persistently displayed in collapsed and expanded states, and the rotating rating stars will be hidden.\
   * Controlled by the `caas-floating-expert-rating` experiment.
   */
  isAvatarRatingPersistent: boolean
  /**
   * The variation of the CTA headings to be used.\
   * Controlled by the `caas-floating-cta-headings` experiment.
   */
  ctaHeadingsVariation: 'control' | 'shop' | 'gear'
  sessionLength?: number
}

export class FloatingCTA {
  // Session storage is the source of truth for `isMinimizationStateLocked`, but this variable stored in memory is used as a fallback.
  private _isMinimizationStateLocked = false
  private minimizationStrategy: FloatingCTAMinimizationStrategy
  private elementIds = {
    overlay: 'curated-cta-overlay',
    cta: 'curated-cta',
    avatar: 'curated-cta-avatar',
    tooltip: 'curated-cta-tooltip',
    heading: 'curated-cta-heading',
    title: 'curated-cta-title',
    rating: 'curated-cta-rating',
    badge: 'curated-cta-badge'
  } as const
  private ctaElement: HTMLDivElement
  private isMinizedSessionStorageKey = 'caas.isFloatingCtaMinimized'
  private isMinimizationStateLockedSessionStorageKey = 'caas.isFloatingCtaLocked'
  private expertSessionStorageKey = 'caas.floatingCtaExpert'
  private imgixQueryParams = '?auto=compress,format&ch=Width,DPR,Save-Data&h=132&w=132'
  /**
   * Callback to be fired when the CTA is clicked.\
   * _Note: This does not apply to clicks to the avatar when maximized._
   */
  public onClick: () => void
  /**
   * If true, the `onClick` callback will be fired when the CTA is clicked while minimized.
   */
  public fireOnClickFromMinimizedState: boolean
  /**
   * If true, the avatar rating will be persistently displayed in collapsed and expanded states, and the rotating rating stars will be hidden.\
   * Controlled by the `caas-floating-expert-rating` experiment.
   */
  public isAvatarRatingPersistent: boolean
  /**
   * The variation of the CTA headings to be used.\
   * Controlled by the `caas-floating-cta-headings` experiment.
   */
  public ctaHeadingsVariation: 'control' | 'shop' | 'gear'

  constructor({
    minimizationStrategy,
    theme,
    isAvatarRatingPersistent,
    ctaHeadingsVariation,
    onClick,
    fireOnClickFromMinimizedState,
    sessionLength = 1
  }: FloatingCTAConstructorArguments) {
    this.fireOnClickFromMinimizedState = fireOnClickFromMinimizedState
    this.isAvatarRatingPersistent = isAvatarRatingPersistent
    this.ctaHeadingsVariation = ctaHeadingsVariation
    this.ctaElement = this.createCtaElement()
    this.theme = theme
    this.align(theme?.alignment || 'right')
    this.onClick = onClick
    this.minimizationStrategy = minimizationStrategy
    this.revivePersistedExpert()

    if (canUseSessionStorage()) {
      this.isMinimizationStateLocked =
        window.sessionStorage.getItem(this.isMinimizationStateLockedSessionStorageKey) === 'true'
    }

    if (this.isMinimizationStateLocked) {
      // If not locked, retrieve the previously-stored value for `isMinimized` from sessionStorage.
      this.isMinimized = window.sessionStorage.getItem(this.isMinizedSessionStorageKey) === 'true'
    } else if (
      this.minimizationStrategy === FloatingCTAMinimizationStrategy.AFTER_SECOND_PAGEVIEW &&
      sessionLength > 1
    ) {
      // Otherwise, if the sessionLength is at least 2 and the minimization strategy allows, auto-minimize now.
      this.isMinimized = true
      this.isMinimizationStateLocked = true
    } else {
      // If the state is not locked, then it should be initially minimized.
      // Because it is not locked, the state will be allowed to toggle after the connect-app loads.
      this.isMinimized = true
    }

    this.addInteractivity()
    this.monitorIdleTime()
    if (sessionLength <= 1 && !this.isMinimized) {
      // If rendering the floating CTA for the first time & it's not minimized, show a tooltip after 2s.
      // The tooltip is hidden when the user clicks anywhere on the page.
      setTimeout(() => {
        this.getElement('tooltip')?.style.setProperty('display', 'block')
        window.addEventListener(
          'click',
          () => {
            this.getElement('tooltip')?.style.setProperty('display', 'none')
          },
          { once: true }
        )
      }, 2_000)
    }
  }

  private monitorIdleTime() {
    if (
      this.isMinimizationStateLocked ||
      this.minimizationStrategy !== FloatingCTAMinimizationStrategy.AFTER_IDLE_TIME
    ) {
      return
    }
    let idleTime = 0
    let interval: NodeJS.Timer
    const handleResetIdleTime = () => {
      idleTime = 0
    }
    const handleIdleTimeReached = () => {
      if (!this.isMinimizationStateLocked) {
        this.isMinimized = true
        this.isMinimizationStateLocked = true
      }
      document.removeEventListener('mousemove', handleResetIdleTime)
      document.removeEventListener('keypress', handleResetIdleTime)
      clearInterval(interval)
    }
    document.addEventListener('mousemove', handleResetIdleTime)
    document.addEventListener('keypress', handleResetIdleTime)
    interval = setInterval(() => {
      // Every second, increment the idle time counter by 1.
      // If the user has been idle for more than the defined idle time, auto-minimize the widget.
      // The event listeners reset the idle time to 0 on any user interaction with the page.
      idleTime += 1
      if (idleTime > MINIMIZATION_IDLE_TIME_IN_SECONDS) {
        handleIdleTimeReached()
      }
    }, 1_000)
  }

  public set theme(value: WidgetTheme) {
    this.ctaElement.setAttribute('style', convertThemeToStyleValue(value, true))
  }

  public set expert({ url, name, title, rating, unreadCount }: RecommendedExpertForFloatingCta) {
    // This setter modifies the elements directly on the DOM.
    if (url && name && rating) {
      this.replaceInnerHtml('avatar', this.createAvatarHtml({ src: url, name, rating }))
    }

    switch (this.ctaHeadingsVariation) {
      case 'shop':
      default:
        this.replaceInnerHtml('heading', 'Shop with an Expert')
        break
      case 'gear':
        // HACK: override for Golfer Geeks and Golf Link. If this treatment wins out we can find a better way to do this.
        if (
          window.curatedSettings?.publisherId === 'AgAAAmwA4m9dU7hPSqi1h0NoSqqxeA' ||
          window.curatedSettings?.publisherId === 'AgAAAmwAk9ygvwQ9Q1-EHwZ9eECndw'
        ) {
          this.replaceInnerHtml('heading', 'Looking for new clubs?')
        } else {
          this.replaceInnerHtml('heading', 'Looking for new gear?')
        }
        break
      case 'control':
        if (name) {
          this.replaceInnerHtml('heading', name)
        }
        break
    }

    if (title) {
      if (this.ctaHeadingsVariation !== 'control' && name) {
        const [firstName, lastName] = name.split(' ')

        // If the name is more than one word, use the first name and last initial.
        const formattedName = lastName ? `${firstName} ${lastName.charAt(0)}` : firstName

        this.replaceInnerHtml('title', `${formattedName} - ${title}`)
      } else {
        this.replaceInnerHtml('title', title)
      }
    }

    if (url && name && title && rating) {
      this.persistExpertToSessionStorage({ url, name, title, rating })
    }

    if (unreadCount != null) {
      if (unreadCount > 0) {
        this.ctaElement
          .querySelector('.curated-cta-notification')
          ?.classList.remove('curated-cta-notification-hidden')
        this.replaceInnerHtml('badge', unreadCount.toFixed(0))
      } else {
        this.ctaElement
          .querySelector('.curated-cta-notification')
          ?.classList.add('curated-cta-notification-hidden')
      }
    }

    if (rating) {
      const ratingElement = this.getElement('rating')
      if (ratingElement) {
        ratingElement.style.setProperty('--rating', rating.toFixed(1))
        ratingElement.ariaLabel = `Rating: ${rating.toFixed(1)} out of 5 stars`
      }
    }
  }

  private persistExpertToSessionStorage(
    expert: Omit<Required<RecommendedExpertForFloatingCta>, 'unreadCount'>
  ) {
    if (canUseSessionStorage()) {
      window.sessionStorage.setItem(this.expertSessionStorageKey, JSON.stringify(expert))
    }
  }

  private revivePersistedExpert() {
    if (canUseSessionStorage()) {
      const item = window.sessionStorage.getItem(this.expertSessionStorageKey)
      const persistedExpert: RecommendedExpertForFloatingCta = item ? JSON.parse(item) : {}
      this.expert = persistedExpert
    }
  }

  public get position(): [number, number] {
    const x = parseFloat(this.ctaElement.getAttribute('data-x') || '0')
    const y = parseFloat(this.ctaElement.getAttribute('data-y') || '0')
    return [x, y]
  }

  public set position([x, y]: [number, number]) {
    this.ctaElement.style.setProperty('translate', `${x}px ${y}px`)
    this.ctaElement.setAttribute('data-x', x.toFixed(4))
    this.ctaElement.setAttribute('data-y', y.toFixed(4))
  }

  // Gets/sets whether the floating CTA is currently in minimized state.
  public get isMinimized() {
    return this.ctaElement.classList.contains('minimized')
  }
  public set isMinimized(value: boolean) {
    if (value !== this.isMinimized) {
      this.ctaElement.classList.toggle('minimized')
      if (canUseSessionStorage()) {
        if (value) {
          window.sessionStorage.setItem(this.isMinizedSessionStorageKey, 'true')
        } else {
          window.sessionStorage.removeItem(this.isMinizedSessionStorageKey)
        }
      }
    }
  }

  // Gets/sets whether the floating CTA can currently be auto-minimized, according to its `minimizationStrategy`.
  public get isMinimizationStateLocked(): boolean {
    if (canUseSessionStorage()) {
      this._isMinimizationStateLocked =
        window.sessionStorage.getItem(this.isMinimizationStateLockedSessionStorageKey) === 'true'
    }
    return this._isMinimizationStateLocked
  }
  public set isMinimizationStateLocked(value: boolean) {
    if (canUseSessionStorage()) {
      if (value) {
        window.sessionStorage.setItem(this.isMinimizationStateLockedSessionStorageKey, 'true')
      } else {
        window.sessionStorage.removeItem(this.isMinimizationStateLockedSessionStorageKey)
      }
    }
    this._isMinimizationStateLocked = value
  }

  private createCtaElement() {
    this.destroy()
    const overlay = document.createElement('div')
    overlay.id = this.elementIds.overlay
    overlay.innerHTML = ctaHtml

    const ctaElement = overlay.firstChild! as HTMLDivElement
    ctaElement.id = this.elementIds.cta
    // Prevents the "hold to drag" from mimicking a secondary click on mobile.
    ctaElement.oncontextmenu = e => e.preventDefault()
    ctaElement.ontouchend = e => e.preventDefault()
    ctaElement.addEventListener('keydown', e => {
      // If the user presses the spacebar or enter key while focused on the CTA, treat it as a click.
      if (e.key === 'Enter' || e.key === ' ') {
        this.handleCtaClick(e)
      }
    })

    // If the `isAvatarRatingPersistent` option is true, remove the rotating rating stars and add the associated styling.
    if (this.isAvatarRatingPersistent) {
      ctaElement.querySelector('#curated-cta-rating')?.remove()
      ctaElement.classList.add('persistent-avatar-rating')
    }

    document.body.appendChild(overlay)
    return ctaElement
  }

  private handleCtaClick = (e: Event) => {
    const { target } = e
    if (!target || !(target instanceof HTMLElement || target instanceof SVGElement)) {
      return
    }

    e.preventDefault()
    e.stopPropagation()

    this.getElement('tooltip')?.style.setProperty('display', 'none')

    // If the user clicks the CTA, lock the minimization state
    this.isMinimizationStateLocked = true

    // if the CTA is minimized, always toggle it.
    if (this.isMinimized) {
      this.toggle()

      // addditionally, if the `fireOnClickFromMinimizedState` option is true, call `onClick`.
      if (this.fireOnClickFromMinimizedState) {
        // for publishers, reduce the z-index of any elements with a z-index of 2147483647 by 1 before calling `onClick`.
        if (window.curatedSettings?.publisherId) {
          this.findAndReduceElementsWithMaxZIndex()
        }

        this.onClick()
      }

      return
    }

    // If the CTA is not minimized and the user clicks the avatar, toggle the CTA.
    // If the user clicks anywhere else on the CTA, call `onClick`.
    if (target.id === this.elementIds.avatar) {
      this.toggle()
    } else {
      // for publishers, reduce the z-index of any elements with a z-index of 2147483647 by 1 before calling `onClick`.
      if (window.curatedSettings?.publisherId) {
        this.findAndReduceElementsWithMaxZIndex()
      }

      this.onClick()
    }
  }

  private addInteractivity() {
    interact(this.ctaElement)
      .draggable({
        manualStart: true,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ],
        listeners: {
          start: ({ target }: InteractEvent) => {
            target.classList.add('dragging')
          },
          move: ({ dx, dy }: InteractEvent) => {
            const [x, y] = this.position
            this.position = [x + dx, y + dy]
          },
          end: ({ target }: InteractEvent) => {
            target.classList.remove('dragging')
          }
        }
      })
      .pointerEvents({
        holdDuration: 500
      })
      .on('hold', ({ interaction, interactable, currentTarget }: PointerEvent) => {
        if (!interaction.interacting()) {
          interaction.start(
            { name: 'drag' },
            interactable,
            currentTarget as HTMLElement | SVGElement
          )
        }
      })
      .on('tap', e => this.handleCtaClick(e))
  }

  private getElement(elementName: keyof typeof this.elementIds): HTMLElement | undefined {
    const el = this.ctaElement.querySelector(`#${this.elementIds[elementName]}`)
    if (el && el instanceof HTMLElement) {
      return el
    }
  }

  private replaceInnerHtml(elementName: keyof typeof this.elementIds, html: string) {
    const element = this.getElement(elementName)
    if (element && html && html !== element.innerHTML) {
      element.innerHTML = html
    }
    return element
  }

  private createAvatarHtml(options: { src: string; name: string; rating: number }) {
    const image = document.createElement('img')
    image.setAttribute('loading', 'lazy')
    image.setAttribute('alt', options.name || '')
    image.setAttribute('src', options.src + this.imgixQueryParams)

    const rating = document.createElement('div')
    rating.classList.add('curated-cta-avatar-rating')
    rating.innerHTML = `
    <img src="${starIcon}" alt="Rating: ${options.rating.toFixed(1)} out of 5 stars" />
    <span>${options.rating.toFixed(1)}</span>
    `

    return image.outerHTML + rating.outerHTML
  }

  private resetPosition() {
    this.ctaElement.style.removeProperty('translate')
    this.ctaElement.removeAttribute('data-x')
    this.ctaElement.removeAttribute('data-y')
  }

  public hide() {
    if (this.isMinimized && !this.isMinimizationStateLocked) {
      this.toggle()
    }
    this.ctaElement.classList.add('curated-cta-hidden')
  }

  public show() {
    if (this.isMinimized && !this.isMinimizationStateLocked) {
      this.toggle()
    }
    this.ctaElement.classList.remove('curated-cta-hidden')
  }

  public destroy() {
    // The `FloatingCTA` class is instantiated after receiving a "widget-ready" message from the connect-app,
    // which can happen more than once per session. Ensure clean slate before trying to create the `ctaElement`.
    document.querySelectorAll(`#${this.elementIds.overlay}`).forEach(element => element.remove())
  }

  public toggle() {
    const [x, y] = this.position
    if (isMobile() && this.isMinimized && x > 16) {
      // If the floating CTA has been minimized AND dragged to the right on a mobile screen,
      // un-minimizing it will cause the right-hand side to be rendered off-screen. To deal with this,
      // smoothly move the CTA element to the left when trying to un-minimize. The '.moving' class
      // adds the `transition` property, and is removed after the transition completes.
      const handleTransitionEnd = (e: TransitionEvent) => {
        if (e.target instanceof HTMLElement && e.target.classList.contains('moving')) {
          e.target.classList.remove('moving')
          this.ctaElement.removeEventListener('transitionend', handleTransitionEnd)
        }
      }
      this.ctaElement.addEventListener('transitionend', handleTransitionEnd)
      this.ctaElement.classList.add('moving')
      this.position = [16, y]
    }
    this.isMinimized = !this.isMinimized
  }

  public align(alignment: 'left' | 'right') {
    this.resetPosition()
    this.ctaElement.classList[alignment === 'left' ? 'add' : 'remove']('align-left')
  }

  /**
   * Reduces the z-index of any elements with a z-index of 2147483647 (or greater) to 2147483646.
   * This is necessary because the floating CTA has a z-index of 2147483647, and it should be the highest z-index on the page.
   *
   * Skips any elements that are part of the floating CTA.
   */
  private findAndReduceElementsWithMaxZIndex() {
    const bodyChildren = document.body.children

    for (const child of Array.from(bodyChildren)) {
      // skip any elements that are part of the floating CTA
      if ((Object.values(this.elementIds) as string[]).includes(child.id)) {
        continue
      }

      const zIndex = parseInt(window.getComputedStyle(child).zIndex)

      if (zIndex >= 2147483647) {
        // preserve any existing inline styles and append the new z-index value
        const existingInlineStyle = child.getAttribute('style')

        child.setAttribute(
          'style',
          `${existingInlineStyle ? existingInlineStyle + ' ' : ''}z-index: 2147483646;`
        )
      }
    }
  }
}
