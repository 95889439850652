import { PartnerId } from './partnerId'
import { isMobile } from './scrollLock'

const Z_INDEX_MAX = '2147483647'

// A side-effect is a function that does something to mutate the DOM, and optionally returns a function that undoes the mutation.
type PartnerSideEffect = () => (() => void) | void
type PartnerSideEffects = { [K in PartnerId]?: Array<PartnerSideEffect> }

const PARTNER_SIDE_EFFECTS: PartnerSideEffects = {
  [PartnerId.CALLAWAY]: [
    moveCallawayBackToTopButtonOutOfTheWay,
    moveCallawayAddToCartBannerOutOfTheWay,
    addPaddingBottomToCallawayClubConfiguratorFooter,
    addPaddingBottomToCallawayNavList
  ],
  [PartnerId.BLUXOME_GOODS]: [
    function () {
      document.documentElement.style.setProperty('background', 'gray')
      return () => {
        document.documentElement.style.removeProperty('background')
      }
    }
  ],
  [PartnerId.SNOWBOARDING_PROFILES]: [moveSnowboardingProfilesAdContainerUp],
  [PartnerId.NIDECKER]: [bringNideckerCookieConsentDialogToForeground]
}

export function getHandleReadySideEffectsForPartner(partnerId: PartnerId) {
  if (partnerId in PARTNER_SIDE_EFFECTS) {
    return PARTNER_SIDE_EFFECTS[partnerId] || []
  }
  return []
}

/*
 * There's a button on Callaway's category pages that is partially covered by our widget on mobile.
 * This function moves it slightly "up" on the page, so there's no overlap.
 * It's invoked as a side effect of connect widget appearing (`handleReady`).
 */
function moveCallawayBackToTopButtonOutOfTheWay() {
  const bttButton = document.getElementById('btt-btn')
  if (bttButton && isMobile()) {
    // The "btt" button isn't in the way for desktop layouts.
    bttButton.style.setProperty('transform', 'translateY(-50%)')
    return () => {
      bttButton.style.removeProperty('transform')
    }
  }
}

/*
 * There's a banner on Callaway's product pages that is completely covered by our widget on mobile.
 * This function moves it "up" on the page, so there's no overlap.
 * It's invoked as a side effect of connect widget appearing (`handleReady`).
 */
function moveCallawayAddToCartBannerOutOfTheWay() {
  const atcBanner = getHTMLElement('#content-container div.pdp-secondary-nav.white.mobile')
  if (isMobile() && atcBanner) {
    atcBanner.style.setProperty('box-shadow', '0px 64px 0px 0px #fff')
    atcBanner.style.setProperty('transform', 'translateY(-64px)')
    return () => {
      atcBanner.style.removeProperty('box-shadow')
      atcBanner.style.removeProperty('transform')
    }
  }
}

/*
 * Inside the club configurator flyout, there's an add-to-cart button pinned to the bottom, with a scrolling box
 * containing the configurator options right above it. This action adds a margin-bottom to the container, and moves the
 * add-to-cart button up enough to fill the space left by the applied margin. Both changes ensure our widget doesn't
 * cover anything important, and preserve functionality of the element.
 */
function addPaddingBottomToCallawayClubConfiguratorFooter() {
  const configFooterElement = getHTMLElement('#product-configurator-ajax div.config-footer')
  if (isMobile() && configFooterElement) {
    // The element already has `padding-bottom: 1rem` set via stylesheet, so we need to add some more.
    configFooterElement.style.setProperty('padding-bottom', 'calc(1rem + 64px)')
    return () => {
      configFooterElement.style.removeProperty('padding-bottom')
    }
  }
}

function addPaddingBottomToCallawayNavList() {
  const navListElement = getHTMLElement('#nav-primary > ul')
  if (isMobile() && navListElement) {
    navListElement.style.setProperty('padding-bottom', '64px')
    return () => {
      navListElement.style.removeProperty('padding-bottom')
    }
  }
}

function moveSnowboardingProfilesAdContainerUp() {
  if (isMobile()) {
    const fixedAdContainerStyle = document.createElement('style')
    fixedAdContainerStyle.setAttribute('type', 'text/css')
    fixedAdContainerStyle.innerHTML = `
      #fixed_container_bottom:not(:empty) {
        box-shadow: rgb(0, 0, 0) 0px 64px 0px 0px;
        transform: translateY(-64px);
        z-index: 999998 !important;
      }
    `
    const allStyleTags = document.getElementsByTagName('style')
    const lastStyleTag = allStyleTags[allStyleTags.length - 1]
    lastStyleTag.parentNode?.appendChild(fixedAdContainerStyle)
    return () => {
      document.removeChild(fixedAdContainerStyle)
    }
  }
}

/*
 * The cookie consent dialog on Nidecker's site is rendered as the document.body's first child,
 * with a z-index of 9999. This means our widget is rendered on top of the dialog, because our iframe
 * is a "later" sibling of the dialog and has a higher z-index. If the cookie consent dialog is visible,
 * this side effect moves the it to the end of the document.body and sets its z-index to the max allowed.
 *
 * No clean-up is returned because the DOM is not noticeably mutated by this change.
 */
function bringNideckerCookieConsentDialogToForeground() {
  const existingDialogElement = getHTMLElement(
    'body > div[role="dialog"][aria-label="cookieconsent"]'
  )
  if (
    existingDialogElement &&
    existingDialogElement.parentElement &&
    // If cookies have already been consented to, this dialog will have display:none;
    existingDialogElement.style.getPropertyValue('display') !== 'none'
  ) {
    const newDialogElement = existingDialogElement.parentElement.removeChild(existingDialogElement)
    newDialogElement.style.setProperty('z-index', Z_INDEX_MAX)
    document.body.appendChild(newDialogElement)
  }
}

function getHTMLElement(selector: string): HTMLElement | undefined {
  const element = document.querySelector(selector)
  if (element && element instanceof HTMLElement) {
    return element
  }
}
