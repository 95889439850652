import { InjectedComponent } from '../../types'
/* Importing asset as a string using the `?raw` suffix.
 * https://vitejs.dev/guide/assets#importing-asset-as-string
 * @ts-ignore */
import inlineCtaHtml from './inline-cta.html?raw'

export const sbpInlineCtaComponent: InjectedComponent = {
  id: 'caas-sbp-inline-cta',
  selector: '.thrv-tabbed-content:has( .thirstylink) + .thrv_wrapper',
  html: inlineCtaHtml
}
