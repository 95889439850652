export type WidgetMode = 'prod' | 'staging' | 'local-staging' | 'dev'

export interface RecommendedExpertForFloatingCta {
  url?: string
  name?: string
  title?: string
  rating?: number
  unreadCount?: number
}

export type CuratedPartnerId =
  | { tenantId: string; publisherId?: never }
  | { tenantId?: never; publisherId: string }

export type CuratedSettings = CuratedFrameConstructorOptions & CuratedPartnerId

export interface CuratedFrameConstructorOptions {
  mode?: WidgetMode
  onEventTrackingRequested?: (eventData: ConnectPartnerTrackingEventData) => void
  onWidgetLoaded?: () => void
  onWidgetLoadRejected?: (reason: string) => void
  scrollEventTarget?: EventTarget
  getCustomerSessionId?: () => string | null
}

interface WidgetStateHandlers {
  expand: () => void
  collapse: () => void
  hide: () => void
}

export interface TransactionData {
  transactionId: string
  timeOfSale: string
  amount: string
  currency?: 'USD' | null
  rawEmail?: string | null
  rawPhoneNumber?: string | null
  lineItems: TransactionLineItem[]
}

export const PopulationType = {
  // This consumer was sampled out of the population (blocked by caas-traffic-gate experiment or located outside of the US).
  SAMPLED_OUT: 'SAMPLED_OUT',
  // This consumer never saw the widget (blocked by caas-experience experiment).
  EXPERIENCE_OFF: 'EXPERIENCE_OFF',
  // This consumer is part of the experimental population (made it through both experiments and the locality check).
  EXPERIENCE_ON: 'EXPERIENCE_ON',
  // This consumer used an override link to force the widget to show (used the "caasOverride=true" url parameter to bypass experiment/locality checks).
  EXPERIENCE_FORCED_ON: 'EXPERIENCE_FORCED_ON'
} as const

export type PopulationType = keyof typeof PopulationType

export const WidgetBlockedReason = {
  SAMPLED_OUT: 'SAMPLED_OUT',
  EXPERIENCE_OFF: 'EXPERIENCE_OFF',
  NO_EXPERTS_ON_SHIFT: 'NO_EXPERTS_ON_SHIFT',
  CLIENT_TIMEZONE_OUTSIDE_US: 'CLIENT_TIMEZONE_OUTSIDE_US',
  STORAGE_INACCESSIBLE: 'STORAGE_INACCESSIBLE',
  // Emissions with `reason`="ERROR" must include an `errorMessage`.
  ERROR: 'ERROR',
  // The connect-app will not send the below messages, but they can be inferred by the connect-widget.
  REQUEST_TIMEOUT: 'REQUEST_TIMEOUT',
  URL_NOT_ALLOWED: 'URL_NOT_ALLOWED'
} as const
export type WidgetBlockedReason = keyof typeof WidgetBlockedReason

export interface NormalizedTransactionData
  extends Omit<TransactionData, 'rawEmail' | 'rawPhoneNumber'> {
  populationType: PopulationType
  sessionId?: string
  consumerId?: string
  leadId?: string
  hashedEmail?: string
  hashedPhoneNumber?: string
}

export type TransactionLineItem = {
  subtotal?: string | null
  quantity?: number | null
} & (
  | {
      gtin: string
      brand?: string
      mpn?: string
    }
  | {
      gtin?: string
      mpn: string
      brand: string
    }
)

export interface CuratedFrameInterface extends WidgetStateHandlers {
  recordSale: (transaction: TransactionData) => void
  update: (curatedSettings: CuratedSettings) => void
  loadWidget: () => void
}

export interface Curated {
  q?: Parameters<Curated>[]
  (key: keyof CuratedFrameInterface, ...args: Parameters<CuratedFrameInterface[typeof key]>): any
}

export interface CtaTrackingProperties {
  type: 'banner' | 'button' | 'widget'
  name?: string
}

interface InjectedComponentBase {
  id: string
  selector: string
  allowedPathnames?: string[]
  html: string
}

type InjectedComponentBackground =
  | { bgSm?: never; bgMd?: never; bgLg?: never }
  | { bgSm: string; bgMd: string; bgLg: string }

export type InjectedComponent = InjectedComponentBase & InjectedComponentBackground

export interface ConnectPartnerTrackingEventData {
  eventDisplayName: string
  eventParams?: Record<string, any>
}
