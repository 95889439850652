import CuratedFrameClass, { createCuratedFrame } from './CuratedFrame'
import type { CuratedSettings, Curated } from './types'
import { TenantId } from './partnerId'
import './styles.css'

declare global {
  interface Window {
    _curated?: CuratedFrameClass
    curated?: Curated
    curatedSettings?: CuratedSettings
  }
}

const curatedInit = (window: Window) => {
  if (!window.curatedSettings) {
    return
  }

  if (window.curatedSettings.tenantId === TenantId.NIDECKER) {
    // We want Nidecker to do this themselves, but will do it for them for now.
    window.curatedSettings.scrollEventTarget = document.body
  }

  // 1. Access the `curatedSettings` defined by the tenant/publisher partner.
  const { tenantId, publisherId, ...options } = window.curatedSettings

  // 2. Create the underlying CuratedFrame class as `window._curated`.
  const c = (window._curated = window._curated
    ? // If `window._curated` already exists, then update the class instance.
      window._curated.update(window.curatedSettings)
    : // If it doesn't exists, then create a CuratedFrame class instance.
      createCuratedFrame(tenantId, publisherId, options))

  // 3. Define a function wrapper for interacting with `window._curated`.
  const curated: Curated = function (key, ...otherArgs) {
    const fn: Function = c[key]
    return fn.apply(c, otherArgs)
  }

  // 4. Execute any commands that were queued before the `window.curated` stub was replaced.
  const { q = [] } = window.curated || {}
  while (q.length) {
    const queuedArgs = q.shift()!
    curated.apply(null, queuedArgs)
  }

  // 5. Replace the stubbed-out `window.curated` with the new function wrapper.
  window.curated = curated

  // 6. Define cleanup actions that are performed when the document unmounts.
  window.addEventListener('unload', function () {
    if (this.curated) delete this.curated
    if (this._curated) delete this._curated
    if (this.curatedSettings) delete this.curatedSettings
  })
}

if (document.readyState !== 'loading') {
  // If this script is being executed after the initial "loading" state has finished, then init right away.
  curatedInit(window)
} else {
  // Otherwise, wait for the state to change to "interactive" or "complete" before initing.
  // Remove the event listener after the script has been executed once. This prevents the script from being executed
  // multiple times if the `document.readyState` changes twice (e.g. from "loading" to "interactive" to "complete").
  document.addEventListener(
    'readystatechange',
    function () {
      if (document.readyState !== 'loading') {
        curatedInit(window)
      }
    },
    {
      once: true
    }
  )
}
