const GA_CLIENT_ID_COOKIE_NAME = '_ga'
const SESSION_TOKEN_COOKIE_NAME = 'deal_connect_sn'
const CANARY_COOKIE_NAME = 'deal_connect_canary'

// This is 180 days (in ms).
const DEFAULT_EXPIRATION_TIME_FROM_NOW = 180 * 24 * 60 * 60 * 1_000

function getAllCookies() {
  return new Map(
    document.cookie.split(';').map(pair => {
      const [key, value] = pair.split('=')
      return [key.trim(), value]
    })
  )
}

function getCookie(cookieName: string): string | null {
  return getAllCookies().get(cookieName) || null
}

function setCookie(cookieName: string, cookieValue: string | null) {
  // Don't set the same value if it already exists.
  if (getCookie(cookieName) === cookieValue) {
    return
  }
  // If the value exists, it expires in 180 days. Otherwise it's already expired.
  const expiration = cookieValue ? Date.now() + DEFAULT_EXPIRATION_TIME_FROM_NOW : 0
  const expires = new Date(expiration).toUTCString()
  document.cookie = `${cookieName}=${cookieValue || ''};expires=${expires};path=/`
}

export function getGoogleAnalyticsCookie() {
  return getCookie(GA_CLIENT_ID_COOKIE_NAME)?.substring(6) || null
}

export function getSessionCookie() {
  return getCookie(SESSION_TOKEN_COOKIE_NAME)
}

export function setSessionCookie(value: string | null) {
  setCookie(SESSION_TOKEN_COOKIE_NAME, value)
}

export function getCanaryCookie() {
  return getCookie(CANARY_COOKIE_NAME)
}

export function setCanaryCookie(value: string) {
  setCookie(CANARY_COOKIE_NAME, value)
}
