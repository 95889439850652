import { TrackingClient, TrackingClientOptions } from '@deal/web-tracking'
import { RudderstackTrackerOptions } from '@deal/web-tracking/client/trackers/RudderstackTracker'
import { getSessionIdFromStorage } from '../localStorage'
import { WidgetMode } from '../types'

const RS_OPTIONS: Record<WidgetMode, RudderstackTrackerOptions> = {
  prod: { writeKey: '2Q1rY2SqGhaaR0w67e7VtkSFh6n', dataPlaneUrl: 'https://rs.curated.com/' },
  staging: {
    writeKey: '2Q1sliRDepcu9dICClgLMi4zSio',
    dataPlaneUrl: 'https://rs.curated-staging.com/'
  },
  'local-staging': {
    writeKey: '2Q1sliRDepcu9dICClgLMi4zSio',
    dataPlaneUrl: 'https://rs.curated-staging.com/'
  },
  dev: { writeKey: '2Q1tIlj3OcWdXakppXkfsFocW1D', dataPlaneUrl: 'https://rs.curated-dev.com/' }
}

const trackingClient = new TrackingClient()

interface ConfigureTrackingClientOptions {
  tenantId: string | null
  publisherId: string | null
  mode: WidgetMode
}

export function configureTrackingClient({
  tenantId,
  publisherId,
  mode
}: ConfigureTrackingClientOptions): TrackingClient {
  const sessionId = getSessionIdFromStorage()
  if (sessionId) {
    trackingClient.configure(context => {
      const trackers: TrackingClientOptions['trackers'] = [
        client => new context.RudderstackTracker(RS_OPTIONS[mode], client)
      ]
      if (mode !== 'prod') {
        trackers.push(client => new context.ConsoleTracker(client))
      }
      const trackingClientOptions: TrackingClientOptions = {
        sessionId,
        application: 'connect-web',
        viewerContext: context.ViewerContext.Consumer,
        trackers
      }
      if (tenantId) {
        trackingClientOptions.tenantId = tenantId
      }
      if (publisherId) {
        trackingClientOptions.publisherId = publisherId
      }
      return trackingClientOptions
    })
  }
  return trackingClient
}

export * from './events'
