import clone from 'lodash/clone'

export function isMobile() {
  return !window.matchMedia('(min-width: 480px)').matches
}

export function syncMobileDocumentHeightToCssProperty() {
  document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`)
}

export function addScrollLock(setScrollYWhenOpened: (scrollY: number) => void) {
  if (isMobile() && !isLockApplied()) {
    setScrollYWhenOpened(clone(window.scrollY))
    document.documentElement.style.setProperty('--scroll-lock-scroll-y', `-${window.scrollY}px`)
    document.documentElement.style.setProperty(
      '--scroll-lock-scroll-bar-width',
      `${window.innerWidth - document.body.offsetWidth}px`
    )
    document.documentElement.classList.add('curated-locked')
  }
}

export function removeScrollLock(getScrollYWhenOpened: () => number | undefined) {
  if (isMobile() && isLockApplied()) {
    document.documentElement.classList.remove('curated-locked')
    const scrollTo = getScrollYWhenOpened()
    if (scrollTo != null) {
      window.scrollTo(0, scrollTo)
    }
  }
}

function isLockApplied() {
  return document.documentElement.classList.contains('curated-locked')
}
