import React, { createContext, useEffect } from 'react'
import type { ChildPostMessageClient, PostMessageClientInterface } from '../client'
import type { ToChildMessage, ToParentMessage } from '../messages'

export const PostMessageClientContext = createContext<
  PostMessageClientInterface<ToChildMessage, ToParentMessage>
>({
  postMessage: () => {
    throw new Error('Invoked `PostMessageClientContext` outside of provider')
  },
  register: () => {
    throw new Error('Invoked `PostMessageClientContext` outside of provider')
  },
  unregister: () => {
    throw new Error('Invoked `PostMessageClientContext` outside of provider')
  }
})

interface PostMessageClientProviderProps extends React.PropsWithChildren {
  client: ChildPostMessageClient
}

export const PostMessageClientProvider: React.FC<PostMessageClientProviderProps> = ({
  client,
  children
}) => {
  useEffect(() => {
    return () => {
      // Unregister all message listeners when unmounting.
      client?.unregister()
    }
  }, [])

  return (
    <PostMessageClientContext.Provider value={client}>{children}</PostMessageClientContext.Provider>
  )
}
