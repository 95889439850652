interface FrameObserverCallbacks {
  intersectionCallback: (areAnyIntersecting: boolean) => any
  mutationCallback: () => any
}

interface FrameObserverTargets {
  /** The elements on the page that the observer is watching for intersection. */
  intersectionTargets: () => NodeListOf<Element>
  mutationTarget: () => Element
}

interface FrameObserversConstructionParameters
  extends FrameObserverCallbacks,
    FrameObserverTargets {}

export class FrameObservers {
  private underlyingIntersectionObserver: IntersectionObserver
  private underlyingMutationObserver: MutationObserver
  private intersectionTargets: FrameObserverTargets['intersectionTargets']
  private mutationTarget: FrameObserverTargets['mutationTarget']

  constructor(constructionParameters: FrameObserversConstructionParameters) {
    this.intersectionTargets = constructionParameters.intersectionTargets
    this.mutationTarget = constructionParameters.mutationTarget

    this.connect = this.connect.bind(this)
    this.disconnect = this.disconnect.bind(this)
    this.reconnectIntersectionObserver = this.reconnectIntersectionObserver.bind(this)

    this.underlyingIntersectionObserver = new IntersectionObserver(entries => {
      const areAnyIntersecting = entries.some(entry => entry.isIntersecting)
      constructionParameters.intersectionCallback.call(null, areAnyIntersecting)
    })
    this.underlyingMutationObserver = new MutationObserver(() => {
      // Try connecting the intersection observer here, because it's target may be undefined when `connect` is invoked.
      this.reconnectIntersectionObserver()
      constructionParameters.mutationCallback.call(null)
    })
  }

  public connect() {
    // The mutation observer is able to be connected right away, because its target is non-nullable.
    this.underlyingMutationObserver.observe(this.mutationTarget(), {
      childList: true,
      subtree: true
    })
    // The intersection observer cannot always be connected, because its targets can be an empty node list.
    // If this connection doesn't happen, it may yet happen as a side effect of the ~mutation~ observer's callback.
    this.reconnectIntersectionObserver()
  }

  public disconnect() {
    this.underlyingIntersectionObserver.disconnect()
    this.underlyingMutationObserver.disconnect()
  }

  private reconnectIntersectionObserver() {
    this.underlyingIntersectionObserver.disconnect()
    this.intersectionTargets().forEach(target => {
      this.underlyingIntersectionObserver.observe(target)
    })
  }
}
