import type { ToParentMessage, ToChildMessage } from '../messages'
import type { DataRequestField } from '../types'

// Extract the type of the message's `value` key from the provided `type`.
export type ExtractMessageValue<M, T> = M extends { type: T; value: infer V } ? V : never

export interface PostMessageClientInterface<
  TInboundMessage extends ToParentMessage | ToChildMessage,
  TOutboundMessage extends ToParentMessage | ToChildMessage
> {
  /** Emits a message to the target window of a specified type & value. */
  postMessage<T extends TOutboundMessage['type']>(
    type: T,
    value: ExtractMessageValue<TOutboundMessage, T>
  ): void
  /** Registers a handler for the specified message type. */
  register<T extends TInboundMessage['type']>(
    type: T,
    handler: (value: ExtractMessageValue<TInboundMessage, T>) => void
  ): void
  /** Unregisters all handlers for all types, or one/all handlers for the specified message type. */
  unregister<T extends TInboundMessage['type']>(
    type?: T,
    handler?: (value: ExtractMessageValue<TInboundMessage, T>) => void
  ): void
}

class PostMessageClient<
  TInboundMessage extends ToParentMessage | ToChildMessage,
  TOutboundMessage extends ToParentMessage | ToChildMessage
> implements PostMessageClientInterface<TInboundMessage, TOutboundMessage>
{
  public targetOrigin: string
  public targetWindow: MessageEventSource | null
  private handlers: Map<string, Set<(value: any) => void>>
  private listener: (e: MessageEvent<TInboundMessage>) => void
  private queuedMessageArguments: Array<[type: string, value: any]>

  constructor(targetOrigin: string) {
    this.targetOrigin = targetOrigin
    this.targetWindow = null
    this.handlers = new Map()
    this.queuedMessageArguments = []
    this.listener = e => {
      if (e.origin === this.targetOrigin) {
        this.handlers.get(e.data.type)?.forEach(callback => {
          callback.call(null, e.data.value)
        })
      }
    }
    window.addEventListener('message', this.listener)
  }

  /** Sets the destination window for outbound postMessages, and sends any messages that were queued prior. */
  public init(targetWindow: MessageEventSource) {
    this.targetWindow = targetWindow
    // Send any queued messages requested before the targetWindow was set.
    const queuedArguments = this.queuedMessageArguments.slice()
    this.queuedMessageArguments = []
    queuedArguments.forEach(args => {
      this.postMessage.apply(this, args as Parameters<typeof this.postMessage>)
    })
  }

  public postMessage<T extends TOutboundMessage['type']>(
    type: T,
    value: ExtractMessageValue<TOutboundMessage, T>
  ): void {
    if (this.targetWindow) {
      this.targetWindow.postMessage({ type, value }, { targetOrigin: this.targetOrigin })
    } else {
      this.queuedMessageArguments.push([type, value])
    }
  }

  public register<T extends TInboundMessage['type']>(
    type: T,
    handler: (value: ExtractMessageValue<TInboundMessage, T>) => void
  ): void {
    const handlersForType = this.handlers.get(type) || new Set()
    handlersForType.add(handler)
    this.handlers.set(type, handlersForType)
  }

  public unregister<T extends TInboundMessage['type']>(
    type?: T,
    handler?: (value: ExtractMessageValue<TInboundMessage, T>) => void
  ): void {
    if (!type) {
      // If no `type` specified, unregister all `handlers`.
      this.handlers = new Map()
    } else {
      if (!handler) {
        // If `type` specified but no `handler`, unregister all handlers for the specified type.
        this.handlers.set(type, new Set())
      } else {
        // If `type` & `handler` both specified, unregister the single handler.
        const handlersForType = this.handlers.get(type) || new Set()
        handlersForType.delete(handler)
        this.handlers.set(type, handlersForType)
      }
    }
  }
}

export class ParentPostMessageClient extends PostMessageClient<ToParentMessage, ToChildMessage> {
  constructor(iframeSrc: string) {
    super(new URL(iframeSrc).origin)
    this.awaitHandshake()
  }

  public awaitHandshake() {
    // The parent is not initialized until it receives a "handshake-initiated" message from its child.
    const handshakeListener = (e: MessageEvent<ToParentMessage>) => {
      if (e.origin === this.targetOrigin && e.data.type === 'handshake-initiated') {
        if (e.source) {
          this.init(e.source)
        } else {
          throw new Error('PostMessageClient instantiated without a targetWindow.')
        }
        window.removeEventListener('message', handshakeListener)
      }
    }
    window.addEventListener('message', handshakeListener)
  }

  public get isTargetWindowClosed() {
    if (this.targetWindow instanceof Window) {
      return this.targetWindow.closed
    }
    return true
  }

  public registerDataResponse(field: DataRequestField, getFieldValue: () => string | null) {
    this.register('data-requested', value => {
      if (value.field === field) {
        this.postMessage('data-request-fulfilled', { field, [value.signature]: getFieldValue() })
      }
    })
  }
}

export class ChildPostMessageClient extends PostMessageClient<ToChildMessage, ToParentMessage> {
  constructor() {
    super(new URL(window.document.referrer).origin)
    // The child is initialized right away, then sends a "handshake-initiated" message to its parent.
    this.init(window.parent)
    this.postMessage('handshake-initiated', undefined)
  }

  public makeDataRequest(field: DataRequestField): Promise<string | null> {
    // Generate a random, 6-digit signature that the parent site will return in its response.
    const signature = Math.floor(100_000 + Math.random() * 900_000)
    let handleDataRequestFulfilledMessage: (
      value: ExtractMessageValue<ToChildMessage, 'data-request-fulfilled'>
    ) => void
    let timeout: NodeJS.Timeout
    // Remove the event listener and clear the timeout before invoking whatever is passed to this function.
    const withCleanupBefore = (cb: () => void) => {
      this.unregister('data-request-fulfilled', handleDataRequestFulfilledMessage)
      clearTimeout(timeout)
      cb.call(null)
    }
    return new Promise(resolve => {
      // Handle the response from the parent.
      handleDataRequestFulfilledMessage = value => {
        if (value.field === field && signature in value) {
          withCleanupBefore(() => resolve(value[signature]))
        }
      }
      this.register('data-request-fulfilled', handleDataRequestFulfilledMessage)
      // Make the request of the parent site.
      this.postMessage('data-requested', { field, signature })
      // If the request isn't fulfilled within 0.2s, then resolve the promise with a null value.
      timeout = setTimeout(() => withCleanupBefore(() => resolve(null)), 200)
    })
  }
}
