import { PartnerId } from './partnerId'

type AllowlistByPartnerId = { [K in PartnerId]?: Array<string | RegExp> }

// for Snowboardingprofiles, we need a specific regex to match the product related / gear review pages
// this takes each keyword, and only matches the exact word (case insensitive) with word boundaries on either side.
// e.g. matching for 'review' keyword
// matches: www.example.com/2022-lib-tech-t-rice-orca-snowboard-review, www.example.com/review-2022-lib-tech-t-rice-orca-snowboard, www.example.com/2022-review-lib-tech-t-rice-orca
// does not match: www.example.com/testreview, www.example.com/BestReviewYet
const spKeywords = ['review', 'reviews', 'top', 'best']
const spRegExp = new RegExp(`\\b(${spKeywords.join('|')})\\b`, 'i')

// for Golfer Geeks, we need a specific regex to match pages with the keyword 'ping'
const ggKeywords = ['ping']
const ggKeywordsRegExp = new RegExp(`\\b(${ggKeywords.join('|')})\\b`, 'i')

// for Golfer Geeks, we need to initially hide the widget on certain pages. This is a list of paths to hide on,
// unless the user has already seen with the widget. The Regexp is used to match the URL pathname exactly to the list.
const ggInitiallyHidePaths = [
  '/',
  '/about/',
  '/review-guidelines/',
  '/play-better/',
  '/best-short-game-drills/',
  '/best-putting-tips/',
  '/best-putting-drills/',
  '/gripping-a-putter/',
  '/how-to-chip-in-golf/',
  '/how-to-putt-better/',
  '/golf-swing-tempo/',
  '/how-to-fix-a-slice/',
  '/how-to-hit-a-driver/',
  '/how-to-hit-a-hybrid/',
  '/hybrid-vs-iron/',
  '/driving-range-tips-beginners/',
  '/driver-swing-vs-iron-swing/',
  '/how-to-clean-golf-clubs/',
  '/golf-terms/',
  '/golf-tips/',
  '/how-many-clubs-in-golf-bag/',
  '/wedge-lofts/',
  '/best-golf-accessories/',
  '/best-golf-bag-organizer/',
  '/best-golf-push-carts/',
  '/best-golf-sunglasses/',
  '/best-golf-gloves/',
  '/best-golf-gloves-for-sweaty-hands/',
  '/best-golf-grips/',
  '/best-golf-training-aids/',
  '/best-golf-putting-aids-reviews/',
  '/wellputt/',
  '/best-golf-clothing-brands/',
  '/best-golf-shorts/',
  '/best-golf-shirts/',
  '/best-golf-hats/',
  '/best-golf-pants/'
]
const ggInitiallyHidePathsRegExp = new RegExp(
  `^(${ggInitiallyHidePaths.map(path => path.replace('/', '\\/')).join('|')})$`,
  'i'
)

// a map of a partner ID to an allowable list of keywords or RegExp patterns, to
// determine whether the widget can load on specific URLs.
const ALLOWLIST_BY_PARTNERID: AllowlistByPartnerId = {
  [PartnerId.CALLAWAY]: [
    // Driver category pages & PDPs.
    '/golf-clubs/drivers',
    '/womens-golf/womens-drivers',
    '/clubs/womens/drivers',
    // Driver custom fitting pages.
    '/custom-fitting/online-driver-selector',
    // Iron category pages & PDPs.
    '/golf-clubs/iron-sets',
    '/womens-golf/womens-irons%2Fcombo-sets',
    '/clubs/womens/iron-sets',
    // Fairway woods category pages & PDPs.
    '/golf-clubs/fairway-woods/',
    '/womens-golf/womens-fairway-woods/',
    // Hybrids category pages & PDPs.
    '/golf-clubs/hybrids/',
    '/womens-golf/womens-hybrids/',
    // Complete sets category pages & PDPs.
    '/golf-clubs/complete-sets/',
    '/womens-golf/womens-complete-sets/',
    // Clubs (generic) category pages.
    // (Lots of paths start with these keywords, so this only validates if it's at the end of the pathname.)
    new RegExp(`\/golf-clubs\/\\B`),
    new RegExp(`\/womensgolf\/\\B`),
    // Cart.
    '/cart'
  ],
  // these keywords match the product related / gear review pages
  [PartnerId.SNOWBOARDING_PROFILES]: [spRegExp]
}

const INITIALLY_HIDE_BY_PARTNERID: AllowlistByPartnerId = {
  [PartnerId.GOLFER_GEEKS]: [ggInitiallyHidePathsRegExp, ggKeywordsRegExp]
}

const BLOCKLIST_BY_PARTNER_ID: AllowlistByPartnerId = {
  [PartnerId.CALLAWAY]: [
    // Customer service pages.
    '/customer-service'
  ],
  [PartnerId.NIDECKER]: [
    // Contact us page.
    '/en/content/54-contact-us'
  ],
  [PartnerId.CANGSHAN]: [
    // Contact us page.
    '/pages/contact-us'
  ]
}

/**
 * This function determines whether loadWidget can be executed,
 * but can be overridden with the caasOverride/rgt parameters
 * or if the bypassAllowlist flag exists in localStorage.
 *
 * @param partnerId tenantId or publisherId
 * @returns whether the loadWidget function can be executed.
 */
export function isUrlAllowlisted(partnerId: PartnerId): boolean {
  // If the allowlist is not defined for the partnerId, then all URLs are allowed.
  if (!(partnerId in ALLOWLIST_BY_PARTNERID)) {
    return true
  }

  return isUrlOnList(ALLOWLIST_BY_PARTNERID[partnerId])
}

/**
 * This function determines whether the current URL is on the initially hide list for the partnerId.
 *
 * @param partnerId tenantId or publisherId
 * @returns whether the URL is on the initially hide list.
 */
export function isCurrentUrlOnInitiallyHideList(partnerId: PartnerId): boolean {
  // If the initially hide list is not defined for the partnerId, then no URLs are blocked.
  if (!(partnerId in INITIALLY_HIDE_BY_PARTNERID)) {
    return false
  }

  return isUrlOnList(INITIALLY_HIDE_BY_PARTNERID[partnerId])
}

/**
 * This function determines whether loadWidget should be blocked,
 * and cannot be overridden. It supersedes the allowlist check.
 *
 * @param partnerId tenantId or publisherId
 * @returns whether the loadWidget function cannot be executed.
 */
export function isUrlBlocklisted(partnerId: PartnerId): boolean {
  // If the blocklist is not defined for the partnerId, then no URLs are blocked.
  if (!(partnerId in BLOCKLIST_BY_PARTNER_ID)) {
    return false
  }

  const isBlocklisted = isUrlOnList(BLOCKLIST_BY_PARTNER_ID[partnerId])
  if (isBlocklisted) {
    console.info(`Curated widget blocked on this page: ${window.location.pathname}`)
  }
  return isBlocklisted
}

function isUrlOnList(list: Array<string | RegExp> = []): boolean {
  const { pathname } = new URL(window.location.href)
  // Check for pattern matches with current pathname.
  for (let pattern of list) {
    pattern = new RegExp(pattern)
    if (pattern.test(pathname)) {
      return true
    }
  }
  return false
}
