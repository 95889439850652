import { WidgetTheme } from '../types'

export const DEFAULT_WIDGET_THEME: WidgetTheme = {
  font: 'FT Polar',
  backgroundColor: '#5162fd',
  textColor: '#ffffff'
} as const

/** Converts the WidgetTheme object to a string that can be used as an element's 'style' attribute. */
export function convertThemeToStyleValue(theme: WidgetTheme, withOffsets: boolean = false): string {
  const {
    font,
    backgroundColor,
    textColor,
    ctaBackgroundColor = textColor,
    ctaTextColor = backgroundColor,
    bottomOffsetMobile = '8px',
    bottomOffsetDesktop = '8px'
  } = theme

  try {
    const placeholderDiv = document.createElement('div')
    placeholderDiv.style.setProperty('--curated-theme-font-family', font)
    placeholderDiv.style.setProperty('--curated-theme-background-color', backgroundColor)
    placeholderDiv.style.setProperty('--curated-theme-text-color', textColor)
    placeholderDiv.style.setProperty('--curated-theme-cta-background-color', ctaBackgroundColor)
    placeholderDiv.style.setProperty('--curated-theme-cta-text-color', ctaTextColor)
    if (withOffsets) {
      placeholderDiv.style.setProperty('--curated-theme-bottom-offset-mobile', bottomOffsetMobile)
      placeholderDiv.style.setProperty('--curated-theme-bottom-offset-desktop', bottomOffsetDesktop)
    }
    return placeholderDiv.getAttribute('style') || ''
  } catch {
    // Return empty string if document is not available. This makes the function safe for SSR.
    return ''
  }
}
