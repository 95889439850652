import { Event, ViewerContext } from '@deal/web-tracking'
import { NormalizedTransactionData } from '../types'

export interface ConnectPartnerTransactionRecordedEventProperties
  extends NormalizedTransactionData {}

export class ConnectPartnerTransactionRecordedEvent extends Event<ConnectPartnerTransactionRecordedEventProperties> {
  static displayName = 'Connect Partner Transaction Recorded'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
